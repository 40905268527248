import { useSelector } from 'react-redux';
import { THEME } from '../pages/shop-setting/shop-theme/constant';

export const useCustomTheme = () => {
  const shopSetting = useSelector(state => state.auth.shopSetting);

  const previewNavbar = JSON.parse(window.sessionStorage.getItem(`THEME_NAVBAR_PREVIEW`));
  const previewSidebar = JSON.parse(window.sessionStorage.getItem(`THEME_SIDEBAR_PREVIEW`));
  const previewProfile = JSON.parse(window.sessionStorage.getItem(`THEME_PROFILE_PREVIEW`));
  const previewMainHeader = JSON.parse(window.sessionStorage.getItem(`THEME_MAINHEADER_PREVIEW`));
  const previewMain = JSON.parse(window.sessionStorage.getItem(`THEME_MAIN_PREVIEW`));
  const previewRegis = JSON.parse(window.sessionStorage.getItem(`THEME_REGIS_PREVIEW`));

  const { navbar, sidebar, profile, mainheader, main, regis } = shopSetting || {};

  const STYLE = {
    navbar: previewNavbar || navbar || THEME.navbar,
    sidebar: previewSidebar || sidebar || THEME.sidebar,
    profile: previewProfile || profile || THEME.profile,
    mainheader: previewMainHeader || mainheader || THEME.mainheader,
    main: previewMain || main || THEME.main,
    regis: previewRegis || regis || THEME.regis
  };

  return STYLE;
};

export const checkPreview = () => {
  const previewNavbar = !!JSON.parse(window.sessionStorage.getItem(`THEME_NAVBAR_PREVIEW`));
  const previewSidebar = !!JSON.parse(window.sessionStorage.getItem(`THEME_SIDEBAR_PREVIEW`));
  const previewProfile = !!JSON.parse(window.sessionStorage.getItem(`THEME_PROFILE_PREVIEW`));
  const previewMainHeader = !!JSON.parse(window.sessionStorage.getItem(`THEME_MAINHEADER_PREVIEW`));
  const previewMain = !!JSON.parse(window.sessionStorage.getItem(`THEME_MAIN_PREVIEW`));
  const previewRegis = !!JSON.parse(window.sessionStorage.getItem(`THEME_REGIS_PREVIEW`));

  const exists = previewNavbar || previewSidebar || previewProfile || previewMainHeader || previewMain || previewRegis;
  return exists;
};
