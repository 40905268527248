import React from 'react';
import notificationIcon from '../layout-icons/notification_outline.svg';
import CommonModal from '../../../../app/components/modals/CommonModal';
import { useIntl } from 'react-intl';
import { DialogContent } from '@mui/material';

const imgStyle = {
  width: 64,
  height: 64
};

function SubscriptionConfirmModal({ open, onClose, onConfirm }) {
  const { formatMessage } = useIntl();
  const itemKeys = ['mgm', 'broadcast', 'noti.booking.all', 'noti.order'];

  const itemNames = itemKeys.map(key => formatMessage({ id: key }));

  return (
    <CommonModal
      open={open}
      onClickConfirm={onConfirm}
      onClickCancel={onClose}
      onClickBackdrop={onClose}
      title={'user.unsubscribe.confirm.title'}
    >
      <DialogContent>
        <div className="text-center mb-4">
          <img src={notificationIcon} style={imgStyle} />
        </div>
        <div className="text-center">{formatMessage({ id: 'user.unsubscribe.confirm' })}</div>
        <div className="text-center">
          {itemNames.map((name, index) => (
            <div key={index}>{name}</div>
          ))}
        </div>
      </DialogContent>
    </CommonModal>
  );
}

export default SubscriptionConfirmModal;
