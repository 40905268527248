import { useSelector } from 'react-redux';

export const useShouldSelectBranch = function() {
  const shop = useSelector(state => state.auth.shop);
  const user = useSelector(state => state.auth.user);

  if (!user) return false;
  else if (user.type !== 'customer' && !user.assignedBranches?.length) return false;
  else if (user.type === 'customer' && user.loggedInBranch) return false;

  return shop && shop.maxNumberOfBranches && !user.loggedInBranch;
};
