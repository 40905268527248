import { createSlice } from '@reduxjs/toolkit';
import { initMGM } from '../../pages/MGM/util/helper';

const initialMemberGetMemberState = {
  mgm: [],
  mgmHistory: [],
  mgmUserHistory: [],
  mgmById: undefined,
  mgmHistoryById: undefined,
  mgmUserHistoryById: undefined,
  mgmUser:undefined
};

export const MemberGetMemberSlice = createSlice({
  name: 'membergetmember',
  initialState: initialMemberGetMemberState,
  reducers: {
    // create MGM
    createdMGM: (state, action) => {
      state.mgm.unshift(action.payload);
    },

    // get MGM
    fetchMGM: (state, action) => {
      state.mgm = action.payload;
    },
    fetchMGMHistory: (state, action) => {
      state.mgmHistory = action.payload;
    },
    fetchMGMUserHistory: (state, action) => {
      state.mgmUserHistory = action.payload;
    },
    fetchMGMUser: (state, action) => {
      state.mgmUserHistory = action.payload;
    },
    fetchUser: (state, action) => {
      state.mgmUser = action.payload;
    },


    // get MGM by _id
    fetchMGMById: (state, action) => {
      state.mgmById = action.payload;
    },
    fetchMGMUserHistoryById: (state, action) => {
      state.mgmUserHistoryById = action.payload;
    },

    // update MGM
    updatedMGM: (state, action) => {
      const newState = state.mgm.map(ent => {
        return ent._id === action?.payload?._id ? action?.payload : ent;
      });
      state.mgm = newState;
    },
    activedMGM: (state, action) => {
      const newState = state.mgmHistory.map(ent => {
        return ent._id === action?.payload?._id ? action?.payload : ent;
      });
      state.mgmHistory = newState;
    },
    // delete MGM
    deletedMGM: (state, action) => {
      state.mgm = state.mgm.filter(el => !action.payload.includes(el._id));
    }
  }
});
