import React from 'react';
import { toAbsoluteUrl } from '../../../_metronic/_helpers';
import { styled } from '@mui/material';
import useMediaQuery from '@mui/material/useMediaQuery';

export function ErrorPageFC() {
  const matches = useMediaQuery('(min-width:589px)');
  const DesktopSize = () => {
    return (
      <Container>
        <FuncrowdIC />
        <div style={{ marginBottom: '5vw' }}></div>
        <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
          <Err404 />
          <Detail>
            <H1>Somethings went wrong...</H1>
            <H1>มีบางอย่างผิดพลาด</H1>
            <br />
            <H2>Sorry, the system is currenly process. </H2>
            <H2> Please wait a moment</H2>
            <H3>ขออภัยขณะนี้ระบบกำลังประมวณผล กรุณารอสักครู่</H3>
          </Detail>
        </div>
      </Container>
    );
  };

  const MobileSize = () => {
    return (
      <Container>
        <FuncrowdIC />
        <div style={{ marginBottom: '5vw' }}></div>
        <Detail>
          <H1>Somethings went wrong...</H1>
          <H1>มีบางอย่างผิดพลาด</H1>
          <br />
          <H2>Sorry, the system is currenly process. </H2>
          <H2> Please wait a moment</H2>
          <H3>ขออภัยขณะนี้ระบบกำลังประมวณผล กรุณารอสักครู่</H3>
        </Detail>
        <div style={{ display: 'flex', justifyContent: 'center' }}>
          <Img404 />
        </div>
      </Container>
    );
  };

  return matches ? <DesktopSize /> : <MobileSize />;
}
const Container = styled('div')(() => ({
   
    padding: '5vw 7vw',
  }));

const FuncrowdIC = styled('div')(() => ({
  background: `url(${toAbsoluteUrl('/media/error/funcrowd.png')})`,
  backgroundSize: '100%  auto',
  width: '30vw',
  height: '7.3vw',
  alignItems: 'center',
  justifyContent: 'center'
}));

const Err404 = styled('div')(() => ({
  background: `url(${toAbsoluteUrl('/media/error/404.png')})`,
  backgroundSize: '50vw 50vw',
  backgroundRepeat: 'no-repeat',
  //   backgroundSize: 'contain',
  width: '50vw',
  height: '50vw',
  alignItems: 'center',
  justifyContent: 'center',
  margin: '1vw'
}));

const Img404 = styled('div')(() => ({
  background: `url(${toAbsoluteUrl('/media/error/err.png')})`,
  backgroundSize: '50vw 50vw',
  backgroundRepeat: 'no-repeat',
  //   backgroundSize: 'contain',
  width: '50vw',
  height: '50vw',
  alignItems: 'center',
  justifyContent: 'center',
  margin: '1vw'
}));

const Detail = styled('div')(() => ({}));

const H1 = styled('h1')(() => ({
  fontSize: '3vw',
  fontWeight: '600'
}));

const H2 = styled('h1')(() => ({
  fontSize: '2vw',
  fontWeight: '600'
}));

const H3 = styled('h1')(() => ({
  fontSize: '1.8vw',
  fontWeight: '300'
}));
