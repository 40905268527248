import { Booking2Slice } from './booking2Slice';
import { getBookingBranchList } from './booking2CRUD';

const { actions } = Booking2Slice;

export const fetchBookingBranchList = () => async dispatch => {
  try {
    const { data } = await getBookingBranchList();
    // console.log(data);
    if (data.branchList.length > 0) {
      dispatch(actions.fetchBookingBranchList(data.branchList));
    }
  } catch (error) {
    console.error(error);
  }
};
