import axios from 'axios';
import { BACKEND_URL } from '../../../../../config';

export const BOOKING_URL = `${BACKEND_URL}/booking2`;
export const BOOKING_ADMIN_URL = `${BACKEND_URL}/booking2/admin`;
export const BOOKING_BRANCH_URL = `${BACKEND_URL}/booking2/branch`;
export const BOOKING_SETTING_URL = `${BACKEND_URL}/booking2/setting`;

export const createBookingBranch = body => axios.post(`${BOOKING_BRANCH_URL}/create`, body);

export const updateCustomerActiveBooking = shopId =>
  axios.patch(`${BOOKING_SETTING_URL}/shopsetting/toggleCustomerActiveBooking/${shopId}`);

export const updateToggleDisplayArchived = shopId =>
  axios.patch(`${BOOKING_SETTING_URL}/shopsetting/toggleDisplayArchived/${shopId}`);

export const fetchBookingShopSetting = shopId => axios.get(`${BOOKING_SETTING_URL}/shopsetting/${shopId}`);

export const toggleActiveBookingBranch = branchId =>
  axios.patch(`${BOOKING_SETTING_URL}/${branchId}/toggleActiveBranch`);

export const editBookingBranch = (branchId, branchForm) =>
  axios.post(`${BOOKING_BRANCH_URL}/edit/${branchId}`, branchForm);

export const duplicateBranch = branchId => axios.post(`${BOOKING_BRANCH_URL}/duplicate/${branchId}`);

export const getBookingBranchList = () => axios.get(`${BOOKING_BRANCH_URL}`);

export const getBookingBranch = branchId => axios.get(`${BOOKING_BRANCH_URL}/${branchId}`);

export const saveBookingServiceSetting = (branchId, body) =>
  axios.post(`${BOOKING_SETTING_URL}/${branchId}/create`, body);

export const getBookingServicesList = branchId => axios.get(`${BOOKING_SETTING_URL}/${branchId}/service`);

export const submitBookingServiceName = (branchId, serviceForm) =>
  axios.post(`${BOOKING_SETTING_URL}/${branchId}/addService`, serviceForm);

export const deleteBookingService = (branchId, serviceId) =>
  axios.delete(`${BOOKING_SETTING_URL}/${branchId}/deleteService/${serviceId}`);

export const getServiceById = (branchId, serviceId) =>
  axios.get(`${BOOKING_SETTING_URL}/${branchId}/service/${serviceId}`);

export const saveEditService = (branchId, serviceId, serviceForm) =>
  axios.patch(`${BOOKING_SETTING_URL}/${branchId}/editService/${serviceId}`, serviceForm);

export const duplicateService = (branchId, serviceId) =>
  axios.post(`${BOOKING_SETTING_URL}/${branchId}/duplicateService/${serviceId}`);

export const createHoliday = (branchId, holidayForm) =>
  axios.post(`${BOOKING_SETTING_URL}/${branchId}/holidays/createHoliday`, holidayForm);

export const getHolidaysList = branchId => axios.get(`${BOOKING_SETTING_URL}/${branchId}/holidays`);

export const deleteHolidayById = (branchId, holidayId) =>
  axios.delete(`${BOOKING_SETTING_URL}/${branchId}/holidays/deleteHoliday/${holidayId}`);

export const deleteManyHolidays = (branchId, input) =>
  axios.patch(`${BOOKING_SETTING_URL}/${branchId}/holidays/deleteManyHolidays`, input);

export const getHolidayById = (branchId, holidayId) =>
  axios.get(`${BOOKING_SETTING_URL}/${branchId}/holidays/${holidayId}`);

export const editHoliday = (branchId, holidayForm) =>
  axios.patch(`${BOOKING_SETTING_URL}/${branchId}/holidays/editHoliday`, holidayForm);

export const getBookingSetting = branchId => axios.get(`${BOOKING_SETTING_URL}/${branchId}/bookingSetting`);

export const getBookingSettingForCreateBooking = branchId =>
  axios.get(`${BOOKING_SETTING_URL}/${branchId}/bookingSettingCreateBooking`);

export const getAvailableTimesListOfBookingService = query => axios.get(`${BOOKING_URL}/availableTimesList?${query}`);

export const getBookingServicesListFirst = branchId => axios.get(`${BOOKING_URL}/servicesList/${branchId}`);

export const createBooking = form => axios.post(`${BOOKING_URL}/createBooking`, form);

export const editBooking = form => axios.patch(`${BOOKING_URL}/editBooking`, form);

export const fetchBookingBranchListCreateBookingPage = shopId =>
  axios.get(`${BOOKING_URL}/bookingBranchsList/${shopId}`);

export const getBookingTimesListFirst = (branchId, serviceDate) =>
  axios.get(`${BOOKING_URL}/timesList/${branchId}?serviceDate=${serviceDate}`);

export const getBookingServicesListSecond = query => axios.get(`${BOOKING_URL}/availableServicesList?${query}`);

export const getBookingServicesListAllBranch = () => axios.get(`${BOOKING_URL}/servicesList`);

export const getBookingsListOfAllBranchsForCalendar = () => axios.get(`${BOOKING_URL}/bookingsListCalendar`);

export const getBookingsListOfBranchForCalendar = branchId =>
  axios.get(`${BOOKING_URL}/bookingsListCalendar/${branchId}`);

export const getBookingDetailById = bookingId => axios.get(`${BOOKING_URL}/booking/${bookingId}`);

export const updateConfirmStatusBooking = bookingId =>
  axios.patch(`${BOOKING_URL}/booking/${bookingId}/confirmBooking`);

export const updateCancelStatusBookingForMember = bookingId =>
  axios.patch(`${BOOKING_URL}/booking/${bookingId}/cancelBookingMember`);

export const updateCancelStatusBookingForAdmin = bookingId =>
  axios.patch(`${BOOKING_URL}/booking/${bookingId}/cancelBookingAdmin`);

export const updateBookingCondition = (branchId, formData) =>
  axios.patch(`${BOOKING_SETTING_URL}/${branchId}/setBookingCondition`, formData);

export const updateWorkingDays = (branchId, values) =>
  axios.patch(`${BOOKING_SETTING_URL}/${branchId}/updateWorkingDays`, values);

export const fetchBookingSettingListWithBranch = shopId =>
  axios.get(`${BOOKING_URL}/bookingSettingAndBranchList/${shopId}`);

export const getBookingsListForAdmin = query => axios.get(`${BOOKING_ADMIN_URL}/bookingsList?${query}`);

export const getBookingsListForCustomer = query => axios.get(`${BOOKING_URL}/customer/bookingsList?${query}`);

export const getBranchClosedDaysIndex = branchId => axios.get(`${BOOKING_URL}/branchcloseddays/${branchId}`);

export const getFullBookingDates = (branchId, selectedMonth) =>
  axios.get(`${BOOKING_URL}/fullbookingdates/${branchId}/${selectedMonth}`);
