import { createSlice } from '@reduxjs/toolkit';

const initialBookingState = {
  items: []
};

export const BookingSlice = createSlice({
  name: 'booking',
  initialState: initialBookingState,
  reducers: {
    fetchBookingList: (state, action) => {
      state.items = action.payload.items;
    }
  }
});
