import { useIntl } from 'react-intl';
import { useSelector } from 'react-redux';

function useBranchOptions({ showActive = true, filterByCurrentBranch = false, includeHQ = true, HQLabel }) {
  const shopBranches = useSelector(state => state.auth.shop?.branches) || [];
  const user = useSelector(state => state.auth.user);
  const intl = useIntl();

  const branches = shopBranches.filter(branch => {
    return (
      (!showActive || branch.isActive) &&
      (!filterByCurrentBranch || !user?.loggedInBranch || branch._id === user?.loggedInBranch?._id)
    );
  });

  const HQOption = { _id: '', name: HQLabel || intl.formatMessage({ id: 'branch.global' }) };
  const branchOptions = [...branches];

  if (includeHQ) {
    branchOptions.unshift(HQOption);
  }

  return {
    branches: branchOptions
  };
}

export default useBranchOptions;
