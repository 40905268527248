import React, { useRef } from 'react';
import { Swiper, SwiperSlide } from 'swiper/react';
import defaultImage from './defaultImage.jpg';
import 'swiper/css';
import 'swiper/css/effect-coverflow';
import 'swiper/css/pagination';

import { Pagination, Keyboard } from 'swiper/modules';

export default function Carousel({ user, groups }) {
  const swiperRef = useRef(null);
  const groupWithPoint = groups.filter(group => group.minimumPoint > 0);
  const sortedGroups = groupWithPoint.sort((a, b) => a.minimumPoint - b.minimumPoint) || [];
  const userGroup = user.memberGroups.filter(group => group.minimumPoint > 0);
  const groupIndex = sortedGroups.findIndex(group => group.name === userGroup[0]?.name);

  if (userGroup.length <= 0) {
    sortedGroups.unshift({ groupImage: defaultImage });
  }

  const initialSlide = groupIndex !== -1 ? groupIndex : 0;

  const renderSlides = (groups, defaultImage) => {
    return groups.length > 0 ? (
      groups.map(group => (
        <SwiperSlide key={group._id}>
          <img style={imgStyle} src={group.groupImage ? group.groupImage : defaultImage} alt="slide_image" />
        </SwiperSlide>
      ))
    ) : (
      <SwiperSlide>
        <img style={imgStyle} src={defaultImage} alt="slide_image" />
      </SwiperSlide>
    );
  };

  return (
    <div style={carouselStyle} className="mt-2">
      <style>{`
        .clickable-area {
          position: absolute;
          top: 0;
          bottom: 0;
          width: 50%;
          cursor: pointer;
          z-index: 10;
        }
        .clickable-area.left {
          left: 0;
        }
        .clickable-area.right {
          right: 0;
        }
      `}</style>
      <Swiper
        key={initialSlide}
        effect={'coverflow'}
        grabCursor={true}
        centeredSlides={true}
        loop={false}
        initialSlide={initialSlide}
        slidesPerView={1}
        pagination={{
          clickable: true
        }}
        keyboard={{
          enabled: true
        }}
        modules={[Pagination, Keyboard]}
        onSwiper={swiper => {
          swiperRef.current = swiper;
        }}
      >
        {/* {user.type === 'customer'
          ? renderSlides(userGroup.length > 0 ? sortedGroups : [], defaultImage)
          : renderSlides(sortedGroups, defaultImage)} */}
        {renderSlides(sortedGroups, defaultImage)}
      </Swiper>
      <div className="clickable-area left" onClick={() => swiperRef.current.slidePrev()}></div>
      <div className="clickable-area right" onClick={() => swiperRef.current.slideNext()}></div>
    </div>
  );
}

const carouselStyle = {
  position: 'relative',
  paddingBottom: '16px'
};

const imgStyle = {
  width: '100%',
  height: 'auto',
  borderRadius: '16px'
};
