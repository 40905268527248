import * as requestFromServer from './groupCrud';
import { groupSlice, callTypes } from './groupSlice';

const { actions } = groupSlice;

export const fetchGroups = () => async dispatch => {
  try {
    dispatch(actions.startCall({ callType: callTypes.action }));
    const res = await requestFromServer.getGroups();
    return dispatch(actions.setGroups({ groups: res.data.groups }));
  } catch (error) {
    error.clientMessage = "can't get member groups from the shop";
    return dispatch(actions.catchError(error));
  }
};

export const createGroup = newGroup => async dispatch => {
  try {
    dispatch(actions.startCall({ callType: callTypes.action }));
    await requestFromServer.addGroup(newGroup);
    return dispatch(actions.endCall({ callType: callTypes.action }));
  } catch (error) {
    error.clientMessage = 'Error occured when creating the new group';
    return dispatch(actions.catchError(error));
  }
};

export const removeGroup = ({ newGroupId, groupId }) => async dispatch => {
  try {
    dispatch(actions.startCall({ callType: callTypes.action }));
    await requestFromServer.deleteGroup({ newGroupId, groupId });
    dispatch(actions.endCall({ callType: callTypes.action }));
  } catch (error) {
    error.clientMessage = 'Error occured when deleting the group';
    dispatch(actions.catchError(error));
  }
};

export const updateGroup = (groupForm, groupId) => async dispatch => {
  try {
    dispatch(actions.startCall({ callType: callTypes.action }));
    await requestFromServer.updateGroup(groupForm, groupId);
    return Promise.resolve(dispatch(actions.endCall({ callType: callTypes.action })));
  } catch (error) {
    error.clientMessage = 'Error occured when deleting the group';
    return Promise.resolve(dispatch(actions.catchError(error)));
  }
};

export const syncGroups = () => async dispatch => {
  try {
    dispatch(actions.startCall({ callType: callTypes.action }));
    await requestFromServer.syncPointGroups();
    return Promise.resolve(dispatch(actions.endCall({ callType: callTypes.action })));
  } catch (error) {
    error.clientMessage = 'Error occured when syncing the groups';
    return Promise.resolve(dispatch(actions.catchError(error)));
  }
};
