import { createSlice } from '@reduxjs/toolkit';

const initialAdvertisementState = {
  items: [],
  advertisementById: undefined,
  advertisementForCustomer: []
};

export const AdvertisementSlice = createSlice({
  name: 'advertisement',
  initialState: initialAdvertisementState,
  reducers: {
    fetchAdvertisementList: (state, action) => {
      state.items = action.payload.items;
    },
    // create Advertisement
    createdAdvertisement: (state, action) => {
      // state.items.unshift(action.payload.advertisement);
      state.items = action.payload.advertisement;
    },

    // get Advertisement by _id
    fetchAdvertisementById: (state, action) => {
      state.advertisementById = action.payload.advertisementById;
    },

    fetchAdvertisementForCustomer: (state, action) => {
      state.advertisementForCustomer = action.payload.advertisementForCustomer;
    },
    // updateCustomer
    updatedAdvertisement: (state, action) => {
      state.items = action.payload.advertisement;
      // state.items.map(entity => {
      //   if (entity._id === action.payload.advertisement._id) {
      //     return action.payload.advertisement;
      //   }
      //   return entity;
      // });
    },
    // deleteCustomer
    deletedAdvertisementById: (state, action) => {
      state.items = state.items.filter(el => {
        return el._id !== action.payload.id;
      });
    },
    // deleteCustomers
    deletedAdvertisementMany: (state, action) => {
      state.items = state.items.filter(el => !action.payload.ids.includes(el._id));
    }
  }
});

