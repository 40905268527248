import React from 'react';
import Dialog from '@mui/material/Dialog';
import styled from 'styled-components';
import { useAppAliasIntlFormatter } from '../../hooks/formatFeatureName';

const CommonModal = ({
  children,
  title,
  open,
  onClickConfirm,
  onClickCancel,
  onClickBackdrop,
  fullWidth = true,
  loading = false
}) => {
  const formatMessage = useAppAliasIntlFormatter();

  return (
    <Dialog open={open} maxWidth="sm" fullWidth={fullWidth} onClose={onClickBackdrop}>
      <ModalTitleStyled>{formatMessage({ id: title })}</ModalTitleStyled>
      {children}
      <Footer>
        {onClickConfirm && !loading && (
          <ButtonStyled type="button" variant="black" onClick={onClickConfirm}>
            {formatMessage({ id: 'button.submit' })}
          </ButtonStyled>
        )}
        {onClickCancel && (
          <ButtonStyled type="button" variant="light" onClick={onClickCancel}>
            {formatMessage({ id: 'button.cancel' })}
          </ButtonStyled>
        )}
      </Footer>
    </Dialog>
  );
};

export default CommonModal;

const ButtonStyled = styled.button`
  width: 100%;
  background: ${props => (props.variant === 'black' ? '#1A1A27' : '#fff')};
  border: 1px solid #1a1a27;
  border-radius: 5px;
  padding: 8px;
  margin-bottom: 5px;
  text-transform: uppercase;

  font-weight: 400;
  font-size: 11px;
  line-height: 25px;
  color: ${props => (props.variant === 'black' ? '#fff' : '#1A1A27')};
`;

const ModalTitleStyled = styled.div`
  font-weight: 500;
  font-size: 16px;
  line-height: 25px;
  color: #464e5f;
  padding: 17px;
  border-bottom: 1px solid rgba(0, 0, 0, 0.1);
  text-align: center;
`;

const Footer = styled.div`
  padding: 20px;
`;
