import React, { createContext, useEffect, useState } from 'react';
import { useMemo } from 'react';
import { useContext } from 'react';
import CountryConfig from '../../CountryConfig';

const I18N_CONFIG_KEY = process.env.REACT_APP_I18N_CONFIG_KEY || 'i18nConfig';
export const defaultLang = CountryConfig[process.env.REACT_APP_COUNTRY]?.i18n || 'en';

const initialState = {
  selectedLang: defaultLang
};

function getConfig() {
  const ls = localStorage.getItem(I18N_CONFIG_KEY);
  if (ls) {
    try {
      return JSON.parse(ls);
    } catch (er) {
      console.error(er);
    }
  }
  return initialState;
}

// Side effect
export function setLanguage(lang) {
  localStorage.setItem(I18N_CONFIG_KEY, JSON.stringify({ selectedLang: lang }));
  window.dispatchEvent(new Event('storage'));
  // window.location.reload();
}

const I18nContext = createContext();

export function useLang() {
  return useContext(I18nContext).selectedLang;
}

export function withI18n(Component) {
  class WithI18n extends React.Component {
    static displayName = `WithI18n(${Component.displayName || Component.name})`;

    static contextType = I18nContext;

    render() {
      return <Component {...this.props} menu={this.context} />;
    }
  }

  return WithI18n;
}

export const I18nConsumer = I18nContext.Consumer;

export function MetronicI18nProvider({ children }) {
  const [lang, setLang] = useState(initialState);
  // const lang = useMemo(getConfig, [ls]);

  useEffect(() => {
    function handleChangeStorage() {
      const ls = localStorage.getItem(I18N_CONFIG_KEY);
      let langData;
      if (ls) {
        try {
          langData = JSON.parse(ls);
        } catch (er) {
          console.error(er);
          langData = initialState;
        }
      } else {
        langData = initialState;
      }

      setLang(langData);
    }

    window.addEventListener('storage', handleChangeStorage);
    return () => window.removeEventListener('storage', handleChangeStorage);
  }, []);

  return <I18nContext.Provider value={lang}>{children}</I18nContext.Provider>;
}
