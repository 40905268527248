import { useSelector } from 'react-redux';

export const useRegisField = () => {
  const { regisField } = useSelector(state => state?.auth);

  const defaultFields = structuredClone(DEFAULT_FIELDS).map((data, idx) => {
    let index = regisField?.defaultField.findIndex(x => x?.field === data?.field);
    return index >= 0 ? regisField?.defaultField[index] : DEFAULT_FIELDS[idx];
  });

  const customFields = structuredClone(CUSTOM_FIELDS).map((data, idx) => {
    let index = regisField?.customField.findIndex(x => x?.field === data?.field);
    return index >= 0 ? regisField?.customField[index] : CUSTOM_FIELDS[idx];
  });

  return { _id: regisField?._id, defaultFields, customFields, genderSetting: regisField?.genderSetting };
};

export const useActivePreview = (defaultFields, customFields) => {
  const all = defaultFields.concat(customFields);

  const active = all.reduce((acc, curr) => {
    acc[curr.field] = Boolean(curr.active);
    return acc;
  }, {});

  const required = all.reduce((acc, curr) => {
    acc[curr.field] = Boolean(curr.required);
    return acc;
  }, {});

  return { active, required };
};

export const useActiveAndRequiredField = () => {
  const { regisField } = useSelector(state => state?.auth);

  const defaultFields = structuredClone(DEFAULT_FIELDS).map((data, idx) => {
    let index = regisField?.defaultField.findIndex(x => x?.field === data?.field);
    return index >= 0 ? regisField?.defaultField[index] : DEFAULT_FIELDS[idx];
  });

  const customFields = structuredClone(CUSTOM_FIELDS).map((data, idx) => {
    let index = regisField?.customField.findIndex(x => x?.field === data?.field);
    return index >= 0 ? regisField?.customField[index] : CUSTOM_FIELDS[idx];
  });

  const all = defaultFields.concat(customFields);

  const active = all.reduce((acc, curr) => {
    acc[curr.field] = Boolean(curr.active);
    return acc;
  }, {});

  const required = all.reduce((acc, curr) => {
    acc[curr.field] = Boolean(curr.required);
    return acc;
  }, {});

  return { active, required };
};

export const createDefaultField = (field, required, active) => ({ field, required, active });
export const createCustomField = (field, name, required, active) => ({ field, name, required, active });

export const DEFAULT_FIELDS = [
  createDefaultField('firstName', false, true),
  createDefaultField('lastName', false, true),
  createDefaultField('gender', false, true),
  createDefaultField('phone', true, true),
  createDefaultField('email', false, true),
  createDefaultField('birthdate', false, true),
  createDefaultField('sales', false, true)
];

export const CUSTOM_FIELDS = [
  createCustomField('custom1', '', false, false),
  createCustomField('custom2', '', false, false),
  createCustomField('custom3', '', false, false),
  createCustomField('custom4', '', false, false),
  createCustomField('custom5', '', false, false),
  createCustomField('custom6', '', false, false),
  createCustomField('custom7', '', false, false)
];

export const DEFAULT_KEYS = ['firstName', 'lastName', 'gender', 'phone', 'email', 'birthdate', 'sales'];
export const CUSTOM_KEYS = ['custom1', 'custom2', 'custom3', 'custom4', 'custom5', 'custom6', 'custom7'];
