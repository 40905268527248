import { BookingSlice } from './slice';
import { getListBooking } from './crud';

const { actions } = BookingSlice;

export const fetchBookingList = (isCustomer, status, date) => async dispatch => {
  try {
    const { data } = await getListBooking(isCustomer, status, date);
    if (data.queryResults) {
      dispatch(actions.fetchBookingList({ items: data.queryResults }));
    }
  } catch (error) {
    console.error(error);
  }
};
