import moment from 'moment';

export const addComma = value => {
  if (!value || !parseFloat(value)) return value;
  return Number(parseFloat(value).toFixed(2)).toLocaleString('en');
};

export const trimNumber = number => {
  let val = number || 0;
  return Number(val.toFixed(2));
};

export const isNumber = input => {
  const regex = /^[0-9\b]+$/;
  if (regex.test(input) || input === '') {
    return true;
  }

  return false;
};

export const convertDateFormat = (date, intl) => {
  const monthIndex = parseInt(moment(date).format('M'));
  const month = intl.formatMessage({ id: `month.${moment.months()[monthIndex - 1].toLowerCase()}` });

  return `${moment(date).format('DD')} ${month} ${moment(date).format('YYYY')}`;
};

export const copyToClipboard = text => {
  // if (navigator.clipboard != undefined) {//Chrome
  //     navigator.clipboard.writeText(text)
  // } else if (window.clipboardData) { // Internet Explorer
  //     window.clipboardData.setData("Text", text);
  // }
  let textField = document.createElement('textarea');
  textField.innerText = text;
  document.body.appendChild(textField);
  textField.select();
  document.execCommand('copy');
  textField.remove();
};

export const combineDateTime = (d, t) => {
  if (!d || !t) return '';
  const date = moment(d);
  const [h, m] = t.split(':');
  return `${date.year()}-${date.format('MM')}-${date.format('DD')}T${h}:${m}:00Z`;
};

export const splitDateTime = date => {
  if (!date) return '';
  const [sDate, sTime] = date.split('T');
  const [h, m] = sTime.split(':');

  return {
    date: new Date(sDate),
    time: `${h}:${m}`
  };
};
