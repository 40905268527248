import CountryConfig from './CountryConfig';
import FeatureFlags from './FeatureFlags';

export const BACKEND_URL = process.env.REACT_APP_BACKEND_IP_ADDRESS;
export const FRONTEND_URL = process.env.REACT_APP_BASE_URL;
export const COUNTRY_CODE = process.env.REACT_APP_COUNTRY;
export const CURRENCY = CountryConfig[COUNTRY_CODE]?.currency;
export const COUNTRY = CountryConfig[COUNTRY_CODE]?.country;
export const DIAL_CODE = FeatureFlags[COUNTRY_CODE].dialCode;
export const DEFAULT_TIMEZONE = CountryConfig[COUNTRY_CODE].defaultTimezone;
export const FEATURE_FLAGS = FeatureFlags[COUNTRY_CODE];
export const ENABLED_LGBTQ = COUNTRY_CODE !== 'MY';
