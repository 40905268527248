import React from 'react';

function WechatLogin(props) {
    console.log(props);
    return (
        <div>
            Wechat Login
        </div>
    );
}

export default WechatLogin;
