import React, { useState } from 'react';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import SvgIcon from '@mui/material/SvgIcon';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import useBranchOptions from '../../hooks/useBranchOptions';
import { getUserByToken, loginToBranch } from '../../modules/Auth/_redux/authCrud';
import * as auth from '../../modules/Auth/_redux/authRedux';
import { useDispatch, useSelector } from 'react-redux';

function BranchPickerMenu() {
  const { branches } = useBranchOptions({
    includeHQ: false,
    showActive: true
  });
  const [anchorEl, setAnchorEl] = useState(null);
  const authToken = useSelector(state => state.auth.authToken);
  const user = useSelector(state => state.auth.user);
  const dispatch = useDispatch();

  const handleOpen = e => {
    setAnchorEl(e.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const onSelectBranch = async branch => {
    await loginToBranch(authToken, branch._id);
    const {
      data: {
        data: { user }
      }
    } = await getUserByToken();

    window.location.reload();

    dispatch({ type: auth.actionTypes.UserLoaded, payload: { user } });
  };

  if (branches.length === 0) {
    return null;
  }

  const filteredBranches =
    user.type !== 'customer'
      ? branches.filter(
          branch => user?.assignedBranches?.length && user?.assignedBranches.some(b => b._id === branch._id)
        )
      : branches;

  return (
    <>
      <SvgIcon onClick={handleOpen} component={KeyboardArrowDownIcon} />
      <Menu anchorEl={anchorEl} open={!!anchorEl} onClose={handleClose}>
        {filteredBranches.map(branch => (
          <MenuItem
            key={branch._id}
            onClick={() => onSelectBranch(branch)}
            disabled={branch._id === user.loggedInBranch?._id}
          >
            {branch.name}
          </MenuItem>
        ))}
      </Menu>
    </>
  );
}

export default BranchPickerMenu;
