import React, { useMemo } from "react";
import { useHtmlClassService } from "../../_core/MetronicLayout";
import { useHistory } from 'react-router-dom';

export function Footer() {
  const today = new Date().getFullYear();
  const uiService = useHtmlClassService();
  const history = useHistory();

  const layoutProps = useMemo(() => {
    return {
      footerClasses: uiService.getClasses("footer", true),
      footerContainerClasses: uiService.getClasses("footer_container", true)
    };
  }, [uiService]);

  return (
    null
    // <div
    //   className={`footer bg-white py-4 d-flex flex-lg-column  ${layoutProps.footerClasses}`}
    //   id="kt_footer"
    //   style={{
    //     zIndex: 1,
    //     // position: 'fixed',
    //     // bottom: '0px',
    //     // width: '100%'
    //   }}
    // >
    //   <div
    //     className={`${layoutProps.footerContainerClasses} d-flex flex-column flex-md-row align-items-center justify-content-between`}
    //   >
    //     <div className="text-dark order-2 order-md-1">
    //       <span className="text-muted font-weight-bold mr-2"></span> &copy;{" "}
    //       <a
    //         onClick={() => history.push({ pathname: '/' })}
    //         rel="noopener noreferrer"
    //         className="text-dark-75 text-hover-primary"
    //       >
    //         Funcrowd
    //       </a>
    //     </div>
    //   </div>
    // </div>
  );
}
