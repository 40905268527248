import { createSlice } from '@reduxjs/toolkit';

const INIT = {
  _id: '',
  shop: '',
  maximum: '',
  minimum: '',
  price: '',
};

const initialWeightState = {
  listLoading: false,
  actionsLoading: false,
  totalCount: 0,
  entities: [],
  weightForEdit: INIT,
  lastError: null
};

export const callTypes = {
  list: 'list',
  action: 'action'
};

export const weightSlice = createSlice({
  name: 'weight',
  initialState: initialWeightState,
  reducers: {
    catchError: (state, action) => {
      state.error = `${action.type}: ${action.payload.error}`;
      console.log(action.payload.error);
      if (action.payload.callType === callTypes.list) {
        state.listLoading = false;
      } else {
        state.actionsLoading = false;
      }
    },
    startCall: (state, action) => {
      state.error = null;
      if (action.payload.callType === callTypes.list) {
        state.listLoading = true;
      } else {
        state.actionsLoading = true;
      }
    },
    endCall: (state, action) => {
      state.error = null;
      if (action.payload.callType === callTypes.list) {
        state.listLoading = true;
      } else {
        state.actionsLoading = true;
      }
    },
    weightByIdFetched: (state, action) => {
      state.actionsLoading = false;
      state.weightForEdit = action.payload.weightForEdit;
      state.error = null;
    },
    weightFetched: (state, action) => {
      const { entities } = action.payload;
      state.listLoading = false;
      state.error = null;
      state.entities = entities;
      state.totalCount = entities.length;
    },
    weightCreated: (state, action) => {
      state.actionsLoading = false;
      state.error = null;
      state.entities.push(action.payload.weight);
    },
    weightDeleted: (state, action) => {
      state.error = null;
      state.actionsLoading = false;
      state.entities = state.entities.filter(el => el.id !== action.payload.id);
    },
    weightsDeleted: (state, action) => {
      state.error = null;
      state.actionsLoading = false;
      state.entities = state.entities.filter(el => !action.payload.ids.includes(el.id));
    }
  }
});
