import { useQuery } from 'react-query';
import { useSelector } from 'react-redux';
import { getListOrders } from '../modules/ECommerce/_redux/order/productsCrud';

export const usePendingOrders = ({ branchId } = {}) => {
  const user = useSelector(state => state.auth.user);
  const isCustomer = user?.type === 'customer';

  return useQuery({
    queryKey: ['pendingOrders', branchId],
    queryFn: async () => {
      const res = await getListOrders(isCustomer, {
        branch: branchId
      });
      return res.data.order.length;
    },
    refetchOnWindowFocus: false,
    enabled: !!user,
    staleTime: 3 * 1000
  });
};
