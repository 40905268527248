import React from 'react';
import { useSelector } from 'react-redux';
import { Helmet } from 'react-helmet';

const DEFAULT_TITLE = 'Funcrowd';

function MetaTags() {
  const shop = useSelector(state => state.auth.shop);
  //setting meta tags in server.js isn't enough to cover all cases

  //for example: start with auth page during line login  /?shop={seqId} then-> /shop/{seqId}. The / route doesn't have meta tags
  //server.js give meta tags to html only for the first page's url that's what /shop/{seqId} also doesn't have meta tags after redirect
  //though if the user refreshes the page or is already logged in, the meta tags are there

  if (!shop) return;
  return (
    <Helmet>
      <title>{shop.name || DEFAULT_TITLE}</title>
    </Helmet>
  );
}

export default MetaTags;
