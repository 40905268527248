import React, { useMemo, useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import objectPath from 'object-path';
import { useSelector } from 'react-redux';
import { Brand } from '../brand/Brand';
import { AsideMenu } from './aside-menu/AsideMenu';
import { useHtmlClassService } from '../../_core/MetronicLayout';
import useWindowSize from '../../../../app/pages/dashboard/chart/WindowSize';
import { BACKEND_URL } from '../../../../config';
// import './extraStyle.css';
import { useIntl } from 'react-intl';
import { useCustomTheme } from '../../../../app/hooks/useCustomTheme';

export function Aside() {
  const uiService = useHtmlClassService();
  const { shop, shopSetting, user } = useSelector(state => state.auth);
  const intl = useIntl();
  const size = useWindowSize();
  const layoutProps = useMemo(() => {
    return {
      disableScroll: objectPath.get(uiService.config, 'aside.menu.dropdown') === 'true' || false,
      asideClassesFromConfig: uiService.getClasses('aside', true),
      disableAsideSelfDisplay: objectPath.get(uiService.config, 'aside.self.display') === false,
      headerLogo: uiService.getLogo()
    };
  }, [uiService]);

  const logoPath = user?.loggedInBranch?.logo ? `${user.loggedInBranch.logo}` : shop?.logo.data;

  // const previewTheme = JSON.parse(window.localStorage.getItem('THEME_PREVIEW'));
  // const previewNav = previewTheme?.navColor;
  // const isPreview = previewNav ? true : false;

  // const initStyle = {
  //   background: ''
  // };

  // const [background, setBackground] = useState(initStyle);

  // useEffect(() => {
  //   const navColor = shopSetting?.backgroundColor?.navColor;
  //   const isShopSetting = navColor ? true : false;

  //   if (isPreview && previewNav) {
  //     setBackground({ background: previewNav });
  //   }
  //   if (isShopSetting && navColor) {
  //     setBackground({ background: navColor });
  //   }
  //   // else {
  //   //   setBackground(initStyle);
  //   // }
  // }, [shopSetting]);

  const customTheme = useCustomTheme();
  const style = customTheme?.sidebar;

  return (
    <>
      {/* begin::Aside */}
      <div
        id="kt_aside"
        className={`aside aside-left  ${layoutProps.asideClassesFromConfig} d-flex flex-column flex-row-auto `}
        style={{ overflow: 'auto', background: style?.bgColor }}
      >
        <Brand />
        <div className="d-flex flex-wrap mb-5">
          {shop && size.width < 550 && (
            <img alt="Logo" className="img-fluid mx-auto rounded" style={{ maxHeight: 60 }} src={logoPath} />
          )}
        </div>

        {/* begin::Aside Menu */}
        <div id="kt_aside_menu_wrapper" className="aside-menu-wrapper flex-column-fluid">
          {layoutProps.disableAsideSelfDisplay && (
            <>
              {/* begin::Header Logo */}
              <div className="header-logo">
                <Link to="">
                  <img alt="logo" src={layoutProps.headerLogo} />
                </Link>
              </div>
              {/* end::Header Logo */}
            </>
          )}

          <AsideMenu disableScroll={layoutProps.disableScroll} />
        </div>
        {/* end::Aside Menu */}
      </div>
      {/* end::Aside */}
    </>
  );
}
