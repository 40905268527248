const FeatureFlags = {
  MY: {
    dialCode: '60',
    login: {
      line: false,
      mobile: true
    },
    registration: {
      requirePassword: true
    },
    adminManage: {
      maximumOwner: 1,
      maximumAdmin: 3
    },
    homepage: {
      logoImageSize: 'contain'
    },
    rate: {
      share: {
        line: false,
        whatsapp: true
      }
    }
  },
  TH: {
    dialCode: '66',
    login: {
      line: true,
      mobile: false
    },
    registration: {
      requirePassword: false
    },
    adminManage: {
      maximumOwner: 10,
      maximumAdmin: 20
    },
    homepage: {
      logoImageSize: 'cover'
    },
    rate: {
      share: {
        line: true,
        whatsapp: true
      }
    }
  },
  PH: {
    dialCode: '63',
    login: {
      line: false,
      mobile: true
    },
    registration: {
      requirePassword: true
    },
    adminManage: {
      maximumOwner: 1,
      maximumAdmin: 3
    },
    homepage: {
      logoImageSize: 'contain'
    },
    rate: {
      share: {
        line: false,
        whatsapp: true
      }
    }
  }
};

export default FeatureFlags;
