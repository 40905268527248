import React from 'react';
import { faHome } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useCustomTheme } from '../../../../../app/hooks/useCustomTheme';
import { useHistory } from 'react-router-dom';
import { useSelector } from 'react-redux';

function HomeButton() {
  const customTheme = useCustomTheme();
  const style = customTheme?.navbar;
  const history = useHistory();
  const seqId = useSelector(state => state.auth.shop?.seqId);

  const handleNavigate = () => {
    if (seqId) {
      history.push(`/shop/${seqId}/home`);
    }
  };
  return (
    <div className="btn btn-icon btn-clean btn-dropdown btn-lg">
      <span className="fa-layers fa-fw">
        <FontAwesomeIcon icon={faHome} inverse size="2x" style={{ color: style.icColor }} onClick={handleNavigate} />
      </span>
    </div>
  );
}

export default HomeButton;
