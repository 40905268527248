import { arrayMove } from '@dnd-kit/sortable';
import { createSlice } from '@reduxjs/toolkit';
import { insertAtIndex } from './contactBoardHelpers';

const INITIAL_STATE = {
  boardForEdit: {
    _id: '',
    name: 'BOARD_NAME',
    columns: [],
    priorityLabel: null,
    labels: [],
    shopUser: {
      user: {
        firstName: 'FIRST_NAME',
        lastName: 'LAST_NAME'
      }
    }
  },
  previousBoard: {
    _id: '',
    name: 'BOARD_NAME',
    columns: []
  },
  draggingState: {
    mode: null,
    prevColumnId: null,
    draggingColumnId: null,
    draggingCardId: null
  },
  currentComments: [],
  priorityLabels: [],
  isLoading: false,
  error: null
};

export const boardSlice = createSlice({
  name: 'board',
  initialState: INITIAL_STATE,
  reducers: {
    boardFetched: (state, action) => {
      state.boardForEdit = action.payload.board;
      //add _id to the new field id (for drag-and-drop)
      state.boardForEdit.columns.forEach(col => {
        col.id = col._id;

        col.cards.forEach(card => {
          card.id = card._id;
        });
      });
      state.isLoading = false;
      state.error = null;
    },
    boardLoading: state => {
      state.isLoading = true;
    },
    boardLoaded: state => {
      state.isLoading = false;
    },
    boardNameChanged: (state, action) => {
      state.boardForEdit.name = action.payload.name;
    },
    columnNameChanged: (state, action) => {
      const { columnId, name } = action.payload;
      const currentCol = state.boardForEdit.columns.find(col => col._id === columnId);
      currentCol.name = name;
      state.isLoading = false;
    },
    cardCreated: (state, action) => {
      const { columnId, card } = action.payload;
      state.boardForEdit.columns.forEach(column => {
        if (column._id === columnId) {
          column.cards.push({ ...card, id: card._id });
        }
      });
      state.isLoading = false;
    },
    sameColumnCardsSwitched: (state, action) => {
      const { activeColumnIndex, activeCardIndex, overCardIndex } = action.payload;
      const currentCards = state.boardForEdit.columns[activeColumnIndex].cards;
      const temp = currentCards[activeCardIndex];
      if (overCardIndex !== -1) {
        currentCards[activeCardIndex] = currentCards[overCardIndex];
        currentCards[overCardIndex] = temp;
      }

      state.isLoading = false;
    },
    differentColumnsCardsSwitched: (state, action) => {
      const { activeColumnIndex, overColumnIndex, overColumn, overCardIndex, activeCard } = action.payload;
      state.boardForEdit.columns[activeColumnIndex].cards = state.boardForEdit.columns[activeColumnIndex].cards.filter(
        card => card.id !== activeCard.id
      );

      activeCard.contactBoardColumn = overColumn.id;

      if (overCardIndex !== -1) {
        activeCard.contactBoardColumn = overColumn._id;
        state.boardForEdit.columns[overColumnIndex].cards = insertAtIndex(
          state.boardForEdit.columns[overColumnIndex].cards,
          overCardIndex,
          activeCard
        );
      } else {
        state.boardForEdit.columns[overColumnIndex].cards.push(activeCard);
      }
      state.isLoading = false;
    },
    columnsSwitched: (state, action) => {
      const { activeColumnIndex, overColumnIndex } = action.payload;
      const col = state.boardForEdit.columns[activeColumnIndex];
      state.boardForEdit.columns[activeColumnIndex] = state.boardForEdit.columns[overColumnIndex];
      state.boardForEdit.columns[overColumnIndex] = col;
      state.isLoading = false;
    },
    columnAdded: (state, action) => {
      const { column } = action.payload;
      state.boardForEdit.columns.push({ ...column, id: column._id });
      state.isLoading = false;
    },
    columnDeleted: (state, action) => {
      const { columnId } = action.payload;
      state.boardForEdit.columns = state.boardForEdit.columns.filter(col => col._id !== columnId);
      state.isLoading = false;
    },
    cardEdited: (state, action) => {
      const { card, columnId, cardId } = action.payload;
      const column = state.boardForEdit.columns.find(col => col._id === columnId);
      const currentCardIndex = column.cards.findIndex(ele => ele._id === cardId);
      column.cards[currentCardIndex] = card;
      state.isLoading = false;
    },
    cardDeleted: (state, action) => {
      const { columnId, cardId } = action.payload;
      const column = state.boardForEdit.columns.find(col => col._id === columnId);
      column.cards = column.cards.filter(card => card._id !== cardId);
      state.isLoading = false;
    },
    currentCommentsFetched: (state, action) => {
      const { comments, columnId, cardId } = action.payload;
      const column = state.boardForEdit.columns.find(col => col._id === columnId);
      const card = column.cards.find(card => card._id === cardId);
      card.comments = comments.map(comment => comment._id);
      state.currentComments = comments;
      state.isLoading = false;
    },
    commentCreated: (state, action) => {
      const { comment, columnId, cardId } = action.payload;
      const column = state.boardForEdit.columns.find(col => col._id === columnId);
      const card = column.cards.find(card => card._id === cardId);
      card.comments.unshift(comment._id);
      state.currentComments.unshift(comment);
      state.isLoading = false;
    },
    commentDeleted: (state, action) => {
      const { columnId, cardId, commentId } = action.payload;
      const column = state.boardForEdit.columns.find(col => col._id === columnId);
      const card = column.cards.find(card => card._id === cardId);
      card.comments = card.comments.filter(comment => comment !== commentId);
      state.currentComments = state.currentComments.filter(comment => comment._id !== commentId);
      state.isLoading = false;
    },
    commentUpdated: (state, action) => {
      const { columnId, cardId, commentId, comment } = action.payload;
      // const column = state.boardForEdit.columns.find(col => col._id === columnId);
      // const card = column.cards.find(card => card._id === cardId);
      const idx = state.currentComments.findIndex(comm => comm._id === commentId);
      state.currentComments[idx] = comment;
      state.isLoading = false;
    },
    labelCreated: (state, action) => {
      const { label } = action.payload;
      state.boardForEdit.labels.push({ ...label, id: label._id });
      state.isLoading = false;
    },
    labelUpdated: (state, action) => {
      const { labelId, label } = action.payload;
      const idx = state.boardForEdit.labels.findIndex(label => label._id === labelId);
      state.boardForEdit.labels[idx] = label;
      state.isLoading = false;
    },
    labelDeleted: (state, action) => {
      const { labelId } = action.payload;
      state.boardForEdit.labels = state.boardForEdit.labels.filter(label => label._id !== labelId);
      state.boardForEdit.columns.forEach(column => {
        column.cards.forEach(card => {
          card.labels = card.labels.filter(label => label !== labelId);
        });
      });
      state.isLoading = false;
    },
    dragStarted: (state, action) => {
      const { prevColumnId, draggingColumnId, draggingCardId } = action.payload;
      state.draggingState = { prevColumnId, draggingColumnId, draggingCardId, mode: null };
      state.previousBoard = state.boardForEdit;
    },
    dragEnded: state => {
      state.draggingState = {
        mode: null,
        prevColumnId: null,
        draggingColumnId: null,
        draggingCardId: null
      };
    },
    catchError: (state, action) => {
      state.error = action.payload;
      state.error.clientMessage = 'something went wrong';
      state.isLoading = false;
    },
    boardReverted: state => {
      if (!state.boardForEdit._id) return;
      state.boardForEdit = state.previousBoard;
      state.isLoading = false;
    },
    priorityLabelsFetched: (state, action) => {
      state.priorityLabels = action.payload.priorityLabels;
    },
    priorityLabelAssigned: (state, action) => {
      state.isLoading = false;
      state.boardForEdit.contact.priorityLabel = action.payload.priorityLabelId;
    }
  }
});

export const boardActions = boardSlice.actions;
