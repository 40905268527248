import { createSlice } from '@reduxjs/toolkit';

export const callTypes = {
  list: 'list',
  action: 'action'
};

const INITIAL_STATE = {
  entities: [],
  listLoading: false,
  actionsLoading: false,
  error: null,
  totalCount: 0,
  couponForEdit: null,
  userCoupons: []
};

export const couponSlice = createSlice({
  name: 'coupons',
  initialState: INITIAL_STATE,
  reducers: {
    startCall: (state, action) => {
      state.error = null;
      if (action.payload.callType === callTypes.list) {
        state.listLoading = true;
      } else {
        state.actionsLoading = true;
      }
    },
    endCall: (state, action) => {
      state.error = null;
      if (action.payload.callType === callTypes.list) {
        state.listLoading = false;
      } else {
        state.actionsLoading = false;
      }
    },
    catchError: (state, action) => {
      state.error = `${action.type}: ${action.payload.error}`;
      if (action.payload.callType === callTypes.list) {
        state.listLoading = false;
      } else {
        state.actionsLoading = false;
      }
    },
    couponsFetched: (state, { payload: { entities, totalCount } }) => {
      state.entities = entities;
      state.error = null;
      state.actionsLoading = false;
      state.totalCount = totalCount || entities.length;
    },
    couponFetched: (state, { payload: { coupon } }) => {
      state.couponForEdit = coupon;
      state.error = null;
      state.actionsLoading = false;
    },
    userCouponsFetched: (state, { payload: { userCoupons } }) => {
      state.userCoupons = userCoupons;
      state.error = null;
      state.actionsLoading = false;
    }
  }
});
