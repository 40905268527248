/* eslint-disable no-unused-vars */
import React from 'react';
import { FormattedMessage, injectIntl } from 'react-intl';

export function PaginationToolbar(props) {
  const { isLoading, paginationProps, intl, centered } = props;
  const {
    sizePerPageList = [
      { text: '3', value: 3 },
      { text: '5', value: 5 },
      { text: '10', value: 10 }
    ],
    sizePerPage,
    totalSize,
    onSizePerPageChange
  } = paginationProps;
  const style = {
    width: '75px'
  };

  const onSizeChange = event => {
    const newSize = +event.target.value;
    onSizePerPageChange(newSize);
  };

  return (
    <div className={`d-flex align-items-center py-3 ${centered ? 'justify-content-center' : ''}`}>
      {isLoading && (
        <div className="d-flex align-items-center">
          <div className="mr-2 text-muted">Loading...</div>
          <div className="spinner spinner-primary mr-10"></div>
        </div>
      )}
      <select
        disabled={totalSize === 0}
        className={`form-control form-control-sm font-weight-bold mr-4 border-0 bg-light ${totalSize === 0 &&
          'disabled'}`}
        onChange={onSizeChange}
        value={sizePerPage}
        style={style}
      >
        {sizePerPageList.map(option => {
          const isSelect = sizePerPage === `${option.page}`;
          return (
            <option key={option.text} value={option.page} className={`btn ${isSelect ? 'active' : ''}`}>
              {option.text}
            </option>
          );
        })}
      </select>
      <span>
        <FormattedMessage id="table.pagination.1" />
        &nbsp;{paginationProps.sizePerPage * paginationProps.page - paginationProps.sizePerPage + 1} &nbsp;
        <FormattedMessage id="table.pagination.2" />
        &nbsp;
        {paginationProps.totalSize < paginationProps.sizePerPage * paginationProps.page
          ? paginationProps.totalSize
          : paginationProps.sizePerPage * paginationProps.page}{' '}
        &nbsp;
        <FormattedMessage id="table.pagination.3" />
        &nbsp;{paginationProps.totalSize} &nbsp;
      </span>
      {/* <PaginationTotalStandalone className="text-muted" {...paginationProps} /> */}
    </div>
  );
}
