import store from '../../../../../redux/store';

export function findColumn(id) {
  let count = 0;
  for (let col of store.getState().board.boardForEdit.columns) {
    if (col.id === id || col.cards.find(card => card.id === id)) {
      return { ...col, index: count };
    }
    count++;
  }
}

function findCard(activeColumnIndex, cardId) {
  const board = store.getState().board.boardForEdit;
  let count = 0;
  for (let card of board.columns[activeColumnIndex].cards) {
    if (card._id === cardId) return { ...card, index: count };
    count++;
  }
}

export function getDraggingData({ activeId, overId }) {
  const activeColumn = findColumn(activeId);
  const activeColumnIndex = activeColumn.index;
  const overColumn = findColumn(overId);
  const overColumnIndex = overColumn.index;
  const isDraggingColumn = activeId === activeColumn._id;

  const activeCard = !isDraggingColumn && findCard(activeColumnIndex, activeId);
  const activeCardIndex = activeCard ? activeCard.index : -1;
  const overCard = !isDraggingColumn && findCard(overColumnIndex, overId);
  const overCardIndex = overCard ? overCard.index : -1;

  return {
    activeColumn,
    activeColumnIndex,
    overColumn,
    overColumnIndex,
    isDraggingColumn,
    activeCard,
    activeCardIndex,
    overCard,
    overCardIndex
  };
}

export function insertAtIndex(arr, idx, ele) {
  return [...arr.slice(0, idx + 1), ele, ...arr.slice(idx + 1, arr.length)];
}
