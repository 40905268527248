import { createSlice } from '@reduxjs/toolkit';

const INIT = {
  _id: '',
  shop: '',
  name: '',
  detail: '',
  fix: {
    active: '',
    price: ''
  },
  byWeight: {
    active: '',
    weight: ''
  },
  COD: '',
  freeMinimum: {
    active: '',
    price: '',
    minimum: ''
  },
  active: '',
  selectedId: '',
  //Only runs first time then the state should be altered to true
  isAutomaticallyFetched: false
};

export const takeFromShopShipping = {
  _id: '1',
  name: 'taken from shop',
  COD: false,
  byWeight: {
    active: false
  },
  freeMinimum: { active: '', price: '', minimum: '' },
  fix: {
    active: true,
    price: 0
  },
  isTakenFromShop: true
};

export const initialShippingSetting = {
  disablePickupAtShop: false
};

const initialShipmentState = {
  listLoading: false,
  actionsLoading: false,
  totalCount: 0,
  entities: [],
  cartEntities: [],
  shipmentForEdit: INIT,
  shippingSetting: initialShippingSetting,
  lastError: null
};

export const callTypes = {
  list: 'list',
  action: 'action'
};

export const shipmentSlice = createSlice({
  name: 'shipment',
  initialState: initialShipmentState,
  reducers: {
    catchError: (state, action) => {
      state.error = `${action.type}: ${action.payload.error}`;
      console.log(action.payload.error);
      if (action.payload.callType === callTypes.list) {
        state.listLoading = false;
      } else {
        state.actionsLoading = false;
      }
    },
    startCall: (state, action) => {
      state.error = null;
      if (action.payload.callType === callTypes.list) {
        state.listLoading = true;
      } else {
        state.actionsLoading = true;
      }
    },
    endCall: (state, action) => {
      state.error = null;
      if (action.payload.callType === callTypes.list) {
        state.listLoading = true;
      } else {
        state.actionsLoading = true;
      }
    },
    shipmentByIdFetched: (state, action) => {
      state.actionsLoading = false;
      state.shipmentForEdit = action.payload.shipmentForEdit;
      state.error = null;
    },
    shipmentFetched: (state, action) => {
      const { entities } = action.payload;
      state.listLoading = false;
      state.error = null;
      state.entities = entities;
      state.totalCount = entities.length;
    },
    //state.entities but with an additional take at shop choice whose schema is "different" from other shipping(no actual "_id")
    //created in another property in order to avoid polluting actual shipment entities
    shipmentChoicesFetched: (state, action) => {
      const { entities } = action.payload;
      state.listLoading = false;
      state.error = null;
      state.cartEntities = [...entities];
    },
    shipmentCreated: (state, action) => {
      state.actionsLoading = false;
      state.error = null;
      state.entities.push(action.payload.shipment);
    },
    shipmentDeleted: (state, action) => {
      state.error = null;
      state.actionsLoading = false;
      state.entities = state.entities.filter(el => el.id !== action.payload.id);
    },
    shipmentsDeleted: (state, action) => {
      state.error = null;
      state.actionsLoading = false;
      state.entities = state.entities.filter(el => !action.payload.ids.includes(el.id));
    },
    setSelectedShipmentId: (state, action) => {
      state.selectedId = action.payload;
    },
    setIsAutomaticallyFetched: (state, action) => {
      state.isAutomaticallyFetched = action.payload;
    },
    shippingSettingFetched: (state, action) => {
      state.shippingSetting = action.payload;
      state.actionsLoading = false;
      state.error = null;
    }
  }
});
