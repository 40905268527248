import React from 'react';
import { MobileDatePicker as DatePickerMui, LocalizationProvider } from '@mui/x-date-pickers';
import dayjs from 'dayjs';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';

function DatePicker({ onChange, value, ...props }) {
  return (
    <LocalizationProvider dateAdapter={AdapterDayjs}>
      <DatePickerMui
        onChange={dateDayJs => {
          onChange(dateDayJs ? dateDayJs.toDate() : null);
        }}
        value={value ? dayjs(value) : null}
        helperText={null}
        slotProps={{
          field: {
            readOnly: true
          }
        }}
        className="form-control"
        format="DD/MM/YYYY"
        {...props}
      />
    </LocalizationProvider>
  );
}

export default DatePicker;
