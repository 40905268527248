export const initCustomrole = {
  dashboard: false,
  members: { invite: false, group: false, user: false },
  advertisement: { advertisement: false, broadcast: false },
  contactManagement: {
    contactAssignable: false
  },
  shopManage: {
    category: false,
    product: false,
    inventory: false,
    payment: false,
    shipment: false,
    VATservice: false,
    order: false
  },
  specialpackage: false,
  booking: { manage: false, setting: false, invite: false },
  booking2: { manage: false, setting: false, invite: false },
  // adminManage: false,
  reward: false,
  coupon: false,
  qrStaff: false,
  qrPoint: false,
  addpointCustomer: false,
  addpoint: false,
  pointHistory: false,
  report: false,
  setting: { shop: false, conversion: false, theme: false, shortcut: false, line: false, contact: false }
};

export const initReportPerm = {
  CUSTOMER_DETAIL: true,
  ACTIVITY: true,
  POINT_HISTORY: true,
  WALLET_HISTORY: true,
  REDEEM: true,
  CURRENT_COUPONS: true,
  USER_COUPON: true,
  PACKAGES: true,
  PACKAGE_USAGE: true,
  USER_PACKAGE: true,
  DEPOSIT_HISTORY: true,
  BOOKING_HISTORY: true,
  BOOKING_HISTORY2: true,
  ORDER_DETAIL: true,
  ORDER_ITEM: true,
  INVENTORY_STOCK: true,
  INVENTORY_TRANSACTION: true,
  ORDER_SUMMARY_ITEM: true
};
