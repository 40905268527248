/**
 * Entry application component used to compose providers and render Routes.
 * */

import React from 'react';
import { Provider } from 'react-redux';
import { BrowserRouter } from 'react-router-dom';
import { PersistGate } from 'redux-persist/integration/react';
import Routes from '../app/Routes';
import { I18nProvider } from '../_metronic/i18n';
import { LayoutSplashScreen } from '../_metronic/layout';
import { transitions, positions, Provider as AlertProvider } from 'react-alert';
import Box from '@mui/material/Box';
import SuccessIcon from '../assets/success_icon.svg';
import ErrorIcon from '../assets/error_icon.png';
import { QueryClient, QueryClientProvider } from 'react-query';
import { ReactQueryDevtools } from 'react-query/devtools';
import MetaTags from './components/MetaTags';

const options = {
  position: positions.MIDDLE,
  timeout: 3000,
  transition: transitions.SCALE,
  containerStyle: {
    left: '0',
    position: 'fixed',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    flexDirection: 'column',
    width: '100%',
    pointerEvents: 'none',
    top: '40%',
    zIndex: '9999'
  }
};

const Alert = ({ options, message }) => {
  // return (<MuiAlert style={{ marginBottom: 8 }} elevation={6} variant="filled" color={options.type}>{message}</MuiAlert>)
  return (
    <Box
      className="d-flex flex-column align-items-center"
      sx={{
        padding: '16px',
        borderRadius: 5,
        backgroundColor: 'rgba(255, 255, 255, 0.8)',
        minWidth: '217px',
        minHeight: '100px',
        boxShadow: '5px 5px 10px rgb(0 0 0 / 20%)',
        mb: 1
      }}
    >
      <div className="pb-5" style={{ fontSize: '15px' }}>
        {message}
      </div>
      <div>
        <img src={options.type == 'success' ? SuccessIcon : ErrorIcon} width={36} height={36} />
      </div>
    </Box>
  );
};

const queryClient = new QueryClient();

export default function App({ store, persistor, basename }) {
  return (
    <Provider store={store}>
      {/* Asynchronously persist redux stores and show `SplashScreen` while it's loading. */}
      <PersistGate persistor={persistor} loading={<div>Loading Persisted Data...</div>}>
        <AlertProvider template={Alert} {...options}>
          {/* Add high level `Suspense` in case if was not handled inside the React tree. */}
          <React.Suspense fallback={<div>Loading Application Data...</div>}>
            {/* Override `basename` (e.g: `homepage` in `package.json`) */}
            <BrowserRouter basename={basename}>
              {/*This library only returns the location that has been active before the recent location change in the current window lifetime.*/}
              {/* <MaterialThemeProvider> */}
              {/* Provide `react-intl` context synchronized with Redux state.  */}
              <I18nProvider>
                {/* Render routes with provided `Layout`. */}
                <QueryClientProvider client={queryClient}>
                  <MetaTags />
                  <Routes />
                  <ReactQueryDevtools initialIsOpen={false} />
                </QueryClientProvider>
              </I18nProvider>
              {/* </MaterialThemeProvider> */}
            </BrowserRouter>
          </React.Suspense>
        </AlertProvider>
      </PersistGate>
    </Provider>
  );
}
