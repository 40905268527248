import { createSlice } from '@reduxjs/toolkit';

const initialBroadcastState = {
  items: [],
  broadcastById: undefined,
  broadcastHistory: []
};

export const BroadcastSlice = createSlice({
  name: 'broadcast',
  initialState: initialBroadcastState,
  reducers: {
    fetchBroadcastList: (state, action) => {
      state.items = action.payload.items;
    },
    // create Broadcast
    createdBroadcast: (state, action) => {
      state.items.unshift(action.payload.broadcast);
      // state.items = action.payload.broadcast;
    },

    // get Broadcast by _id
    fetchBroadcastById: (state, action) => {
      state.broadcastById = action.payload.broadcastById;
    },
    fetchBroadcastHistory: (state, action) => {
      state.broadcastHistory = action.payload.broadcastHistory;
    },

    // update Broadcast
    updatedBroadcast: (state, action) => {
      const newState = state.items.map(entity => {
        return entity._id === action?.payload?.broadcast?._id ? action?.payload?.broadcast : entity;
      });
      state.items = newState;
    },
    // delete Broadcast
    deletedBroadcastById: (state, action) => {
      state.items = state.items.filter(el => {
        return el._id !== action.payload.id;
      });
    },
    // delete Broadcast
    deletedBroadcastMany: (state, action) => {
      state.items = state.items.filter(el => !action.payload.ids.includes(el._id));
    }
  }
});
