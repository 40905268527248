import { createSlice } from '@reduxjs/toolkit';

const INIT = {
  _id: '',
  title: '',
  description: '',
  start: null,
  end: null,
  isActive: true,
  image: [],
  resizeImage: ''
};

const initialAnnouncementsState = {
  listLoading: false,
  actionsLoading: false,
  totalCount: 0,
  entities: [],
  announcementForEdit: INIT,
  lastError: null
};

export const callTypes = {
  list: 'list',
  action: 'action'
};

export const announcementsSlice = createSlice({
  name: 'announcements',
  initialState: initialAnnouncementsState,
  reducers: {
    catchError: (state, action) => {
      state.error = `${action.type}: ${action.payload.error}`;
      console.log(action.payload.error);
      if (action.payload.callType === callTypes.list) {
        state.listLoading = false;
      } else {
        state.actionsLoading = false;
      }
    },
    startCall: (state, action) => {
      state.error = null;
      if (action.payload.callType === callTypes.list) {
        state.listLoading = true;
      } else {
        state.actionsLoading = true;
      }
    },
    endCall: (state, action) => {
      state.error = null;
      if (action.payload.callType === callTypes.list) {
        state.listLoading = true;
      } else {
        state.actionsLoading = true;
      }
    },
    announcementFetched: (state, action) => {
      state.actionsLoading = false;
      state.announcementForEdit = action.payload.announcementForEdit;
      state.error = null;
    },
    announcementsFetched: (state, action) => {
      const { entities } = action.payload;
      state.listLoading = false;
      state.error = null;
      state.entities = entities;
      state.totalCount = entities.length;
    },
    announcementCreated: (state, action) => {
      state.actionsLoading = false;
      state.error = null;
      state.entities.push(action.payload.announcement);
    },
    announcementDeleted: (state, action) => {
      state.error = null;
      state.actionsLoading = false;
      state.entities = state.entities.filter(el => el.id !== action.payload.id);
    },
    announcementsDeleted: (state, action) => {
      state.error = null;
      state.actionsLoading = false;
      state.entities = state.entities.filter(el => !action.payload.ids.includes(el.id));
    }
  }
});
