import { useEffect, useState } from 'react';
import liff from '@line/liff';

export function useLiffInit({ enabled = true } = {}) {
  const [liffLoaded, setLiffLoaded] = useState(false);

  useEffect(() => {
    if (!enabled) return;
    const initializeLiff = async () => {
      try {
        await liff.init({ liffId: process.env.REACT_APP_LIFF_ID });
        setLiffLoaded(true);
      } catch (error) {
        console.error('liff init error', error.message);
      }
    };

    initializeLiff();
  }, [enabled]);

  return {
    liffLoaded
  };
}
