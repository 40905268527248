import { createSlice } from '@reduxjs/toolkit';

const initialBookingState = {
  branchList: [],
  bookingList: []
};

export const Booking2Slice = createSlice({
  name: 'booking2',
  initialState: initialBookingState,
  reducers: {
    fetchBookingBranchList: (state, action) => {
      state.branchList = action.payload;
    }
  }
});
