export const THEME = {
  navbar: { bgColor: '#1a1a27',  color: '#80808F',  icColor: '#ffffff' },
  sidebar: {
    shopNameColor: '#ffffff',
    bgColor: '#1e1e2d',
    color: '#b5b5c3',
    focusBgColor: '#7ab8b6',
    focusColor: '#ffffff',
    hoverBgColor: '#242436',
    hoverColor: '#ffffff',
    subBgColor: '#000000',
    subColor: '#b5b5c3',
    subFocusBgColor: '#7ab8b6',
    subFocusColor: '#ffffff',
    subHoverBgColor: '#1e1e2d',
    subHoverColor: '#ffffff'
  },
  profile: {
    bgColor: '#1e1e2d',
    color: '#b5b5c3',
    expireBgColor: '#17b987',
    expireColor: '#000000',
    clearBgColor: '#E5EAEE',
    clearColor: '#464E5F',
    logoutBgColor: '#4A7DFF',
    logoutColor: '#ffffff',
    demoRoleColor: '#ffffff',
    notifyBgColor: '#d8d0ff',
    notifyColor: '#000000'
  },
  mainheader: { bgColor: '#ffffff', color: '#1a1a27', pointColor: '#15b7b3' },
  main: { bgColor: '#5E73B7', bg: '' },
  regis: { bgColor: '#5E73B7', bg: '' }
};
