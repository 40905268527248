import { createSlice } from '@reduxjs/toolkit';

const initialConditionState = {
  cond: [],
  condList: Object,
  condById: undefined
};

export const ConditionSlice = createSlice({
  name: 'filterCondition',
  initialState: initialConditionState,
  reducers: {
    // create Condition
    createdCond: (state, action) => {
      state.cond.unshift(action.payload);
    },

    // get Condition
    fetchCond: (state, action) => {
      state.cond = action.payload;
    },

    // get Condition by _id
    fetchCondById: (state, action) => {
      state.condById = action.payload;
    },

    // update Condition
    updatedCond: (state, action) => {
      const newState = state.cond.map(ent => {
        return ent._id === action?.payload?._id ? action?.payload : ent;
      });
      state.mgm = newState;
    },
    // delete Condition
    deletedCond: (state, action) => {
      state.cond = state.cond.filter(el => !action.payload.includes(el._id));
    },
    // get Condition List
    fetchCondList: (state, action) => {
      state.condList = action.payload;
    }
  }
});
