import { createSlice } from "@reduxjs/toolkit"



const groupInitialState = {
    groups: [],
    listLoading: false,
    actionsLoading: false,
    error: null
}

export const callTypes = {
    list: "list",
    action: "action"
}

export const groupSlice = createSlice({
    name: "group",
    initialState:  groupInitialState,
    reducers:  {
        startCall: (state, action) => {
            state.error = null;
            if (action.payload.callType === callTypes.list) {
                state.listLoading = true
            } else {
                state.actionsLoading = true
            }
        },
        endCall: (state, action) => {
            state.error = null;
            if (action.payload.callType === callTypes.list) {
                state.listLoading = false
            } else {
                state.actionsLoading = false
            }
        },  
        catchError: (state, action) => {
            state.error = `${action.type}: ${action.payload.error}`
            if (action.payload.callType === callTypes.list) {
                state.listLoading = false
            } else {
                state.actionsLoading = false
            }
        },
        setGroups: (state, action) => {
            state.error = null;
            state.actionsLoading = false;
            state.groups = action.payload.groups;
        }
    }
})

