import { createSlice } from '@reduxjs/toolkit';

const initialUserPosState = {
  userPos: [],
  userPosById: undefined
};

export const UserPosSlice = createSlice({
  name: 'userPos',
  initialState: initialUserPosState,
  reducers: {
    // create POS
    createdUserPos: (state, action) => {
      // state.userPos.unshift(action.payload);
    },

    // get POS
    fetchUserPos: (state, action) => {
      state.userPos = action.payload;
    },

    // get POS by _id
    fetchUserPosById: (state, action) => {
      state.userPos = action.payload;
    },

    // update POS
    updatedUserPos: (state, action) => {
      const newState = state.userPos.map(ent => {
        return ent._id === action?.payload?._id ? action?.payload : ent;
      });
      state.mgm = newState;
    },
    // delete POS
    deletedUserPos: (state, action) => {
      state.userPos = state.userPos.filter(el => !action.payload.includes(el._id));
    }
  }
});
