import axios from 'axios';
import { BACKEND_URL } from '../../../../../config';
import { OrderStatus } from '../../../../utils/mapOrderStatus';

export const PRODUCTS_URL = `${BACKEND_URL}/order/admin`;
export const PRODUCTS_CUS_URL = `${BACKEND_URL}/order/`;

// CREATE =>  POST: add a new product to the server
export function createProduct(product) {
  return axios.post(PRODUCTS_URL, product, { headers: { 'Content-Type': 'multipart/form-data' } });
}

// READ
export function getAllProducts() {
  return axios.get(PRODUCTS_URL);
}

export function getProductById(productId) {
  return axios.get(`${PRODUCTS_URL}/${productId}`);
}

// Method from server should return QueryResultsModel(items: any[], totalsCount: number)
// items => filtered/sorted result
export function findProducts(token, queryParams = {}, controller) {
  const options = { headers: { Authorization: `Bearer ${token}` } };
  if (controller) {
    options.controller = controller;
  }
  return axios.get(`${PRODUCTS_URL}?${new URLSearchParams(queryParams)}`, options);
}

// UPDATE => PUT: update the procuct on the server
export function updateProduct(product) {
  return axios.put(PRODUCTS_URL, product, { headers: { 'Content-Type': 'multipart/form-data' } });
}

// UPDATE Status
export function updateStatusForProducts(ids, status) {
  return axios.post(`${PRODUCTS_URL}/updateStatusForProducts`, {
    ids,
    status
  });
}

// DELETE => delete the product from the server
export function deleteProduct(productId) {
  return axios.delete(`${PRODUCTS_URL}/${productId}`);
}

// DELETE Products by ids
export function deleteProducts(ids) {
  return axios.post(`${PRODUCTS_URL}/deleteProducts`, { ids });
}

export function getListOrders(isCustomer, { branch }) {
  let url = isCustomer ? PRODUCTS_CUS_URL : PRODUCTS_URL;
  return axios.get(url, {
    params: {
      branch,
      status: OrderStatus.waitConfirm
    }
  });
}
