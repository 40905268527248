import React, { useEffect, useState, useMemo } from 'react';
import { AsideMenuList } from './AsideMenuList';
import { useHtmlClassService } from '../../../_core/MetronicLayout';
import { BACKEND_URL, FRONTEND_URL } from '../../../../../config';
import { useDispatch, useSelector } from 'react-redux';

import axios from 'axios';
import { useCustomTheme } from '../../../../../app/hooks/useCustomTheme';

export function AsideMenu({ disableScroll }) {
  const uiService = useHtmlClassService();
  const layoutProps = useMemo(() => {
    return {
      layoutConfig: uiService.config,
      asideMenuAttr: uiService.getAttributes('aside_menu'),
      ulClasses: uiService.getClasses('aside_menu_nav', true),
      asideClassesFromConfig: uiService.getClasses('aside_menu', true)
    };
  }, [uiService]);

  const customTheme = useCustomTheme();
  const style = customTheme?.sidebar;

  return (
    <>
      {/* begin::Menu Container */}
      <div
        style={{ background: style.bgColor }}
        id="kt_aside_menu"
        data-menu-vertical="1"
        className={`aside-menu my-4 `}
        // {...layoutProps.asideMenuAttr}
      >
        <AsideMenuList layoutProps={layoutProps} />
      </div>
      {/* end::Menu Container */}
    </>
  );
}
