import React, { useEffect, useState } from 'react';
import { Container } from './styles';
import TextField from '@mui/material/TextField';
import axios from 'axios';
import { CURRENCY, BACKEND_URL } from '../../../config';
import { injectIntl } from 'react-intl';
import Dialog from '@mui/material/Dialog';
import styled from 'styled-components';
import { useAlert } from 'react-alert';
import { addComma } from '../../utils/comma';
import { useSelector } from 'react-redux';
import { Switch, Button } from '@mui/material/';
import { useIsShoppingCartActive, useIsWalletActive } from '../../hooks/useFeatureFlag';
import { useAppAliasIntlFormatter } from '../../hooks/formatFeatureName';

export const ConversionType = {
  MoneyToPoint: 'Money to Point',
  PointToMoney: 'Point to Money',
  CreditToMoney: 'Credit to Money',
  AddWallet: 'Add Wallet',
  DeductWallet: 'Deduct Wallet'
};

const ConversionRate = ({ intl }) => {
  const formatMessage = useAppAliasIntlFormatter();
  const { shopMenuSetting, shopPointandWalletSetting, customerMenuSetting } = useSelector(state => state.auth);
  const { shop } = useSelector(state => state.auth);
  const alert = useAlert();
  const [loading, setLoading] = useState(false);
  const [id, setId] = useState(undefined);
  const [form, setForm] = useState({
    id: undefined,
    use: undefined,
    earn: undefined,
    type: ConversionType.MoneyToPoint,
    currency: shop?.currency || CURRENCY
  });
  const [conversionRate, setConversionRate] = useState();
  const [openDialog, setOpenDialog] = useState(false);
  const [conversionRateList, setConversionRateList] = useState({});
  const [walletActive, setWalletAcitve] = useState({ add: false, deduct: false });
  const activeWallet = useIsWalletActive();
  const isReadOnly = !!useSelector(state => state.auth.user.assignedBranches).length;

  const pointText =
    shopPointandWalletSetting?.point[0][intl.locale.toUpperCase()] || intl.formatMessage({ id: 'menu.point' });

  const enableShoppingCart = useIsShoppingCartActive();

  useEffect(() => {
    getListConversionRate();
  }, []);

  const getListConversionRate = async () => {
    const { data } = await axios.get(`${BACKEND_URL}/rate`);
    const { conversionRate } = data;
    const MoneyToPoint = conversionRate.find(item => item.type === ConversionType.MoneyToPoint);
    const PointToMoney = conversionRate.find(item => item.type === ConversionType.PointToMoney);
    const CreditToMoney = conversionRate.find(item => item.type === ConversionType.CreditToMoney);
    const AddWallet = conversionRate.find(item => item.type === ConversionType.AddWallet);
    const DeductWallet = conversionRate.find(item => item.type === ConversionType.DeductWallet);

    setConversionRateList({ MoneyToPoint, PointToMoney, CreditToMoney, AddWallet, DeductWallet });
  };

  const handleOnChange = event => {
    if (event.target.value < 0) return;
    setForm({ ...form, [event.target.name]: event.target.value });
  };

  const handleOnSubmit = async event => {
    setLoading(true);
    event.preventDefault();
    alert.removeAll();

    if ((form.use && !form.earn) || (!form.use && form.earn)) {
      setLoading(false);
      return alert.error(intl.formatMessage({ id: 'alert.conversion.rate' }));
    }

    try {
      const { data } = id
        ? await axios.patch(`${BACKEND_URL}/rate/${id}`, {
            ...form,
            type: openDialog,
            isActive: openDialog === 'Add Wallet' ? walletActive.add : 'Deduct Wallet' ? walletActive.deduct : undefined
          })
        : await axios.post(`${BACKEND_URL}/rate`, {
            ...form,
            type: openDialog,
            isActive: openDialog === 'Add Wallet' ? walletActive.add : 'Deduct Wallet' ? walletActive.deduct : undefined
          });
      if (data.success) {
        alert.success(
          intl.formatMessage({ id: id ? 'alert.update.conversion.rate.success' : 'alert.add.conversion.rate.success' })
        );
        setOpenDialog(false);
        getListConversionRate();
      }
      setLoading(false);
    } catch (e) {
      const { message } = e.response.data;
      alert.error(message);
      setLoading(false);
    }
  };

  const getLabel = () => {
    if (openDialog === ConversionType.CreditToMoney) {
      return {
        first:
          shopPointandWalletSetting?.point[1][intl.locale.toUpperCase()] || intl.formatMessage({ id: 'menu.credit' }),
        second: formatMessage({ id: 'shop.currency' })
      };
    } else if (openDialog === ConversionType.MoneyToPoint) {
      return {
        first: formatMessage({ id: 'shop.currency' }),
        second: pointText
      };
    } else if (openDialog === ConversionType.PointToMoney) {
      return {
        first: pointText,
        second: formatMessage({ id: 'shop.currency' })
      };
    } else if (openDialog === ConversionType.AddWallet) {
      return {
        first: formatMessage({ id: 'shop.currency' }),
        second: pointText
      };
    } else {
      return {
        first: formatMessage({ id: 'shop.currency' }),
        second: pointText
      };
    }
  };

  const handleOpenDialog = mode => {
    let data;
    switch (mode) {
      case ConversionType.MoneyToPoint: {
        data = conversionRateList.MoneyToPoint;
        break;
      }
      case ConversionType.CreditToMoney: {
        data = conversionRateList.CreditToMoney;
        break;
      }
      case ConversionType.PointToMoney: {
        data = conversionRateList.PointToMoney;
        break;
      }
      case ConversionType.AddWallet: {
        data = conversionRateList.AddWallet;
        break;
      }
      default: {
        data = conversionRateList.DeductWallet;
        break;
      }
    }

    if (data) {
      setForm(
        data || {
          id: undefined,
          use: undefined,
          earn: undefined,
          type: mode,
          currency: shop?.currency || CURRENCY
        }
      );
      setId(data._id || '');
    } else {
      setForm({
        id: undefined,
        use: undefined,
        earn: undefined,
        type: mode,
        currency: shop?.currency || CURRENCY
      });
      setId('');
    }
    setOpenDialog(mode);
  };

  const [selected, setSelected] = useState({
    cart: true,
    wallet: false
  });

  const select = val => {
    let obj = { ...selected };

    Object.keys(obj).forEach(data => {
      obj[data] = false;
    });
    obj[val] = true;
    setSelected(obj);
  };
  const background = { background: 'revert' };

  const active = async val => {
    let obj = { ...walletActive };
    obj[val] = !obj[val];
    setWalletAcitve(obj);

    const { use, earn, type, currency, _id } =
      val === 'add' ? conversionRateList.AddWallet : conversionRateList.DeductWallet;

    // console.log({ use, earn, type, currency });
    // console.log(!obj[val]);

    try {
      _id
        ? await axios.patch(`${BACKEND_URL}/rate/${_id}`, {
            isActive: obj[val],
            use,
            earn,
            type,
            currency
          })
        : await axios.post(`${BACKEND_URL}/rate`, {
            isActive: obj[val],
            use: null,
            ear: null,
            type: null,
            currency: null
          });
      alert.success(
        intl.formatMessage({ id: id ? 'alert.update.conversion.rate.success' : 'alert.add.conversion.rate.success' })
      );
      getListConversionRate();

      setLoading(false);
    } catch (e) {
      const { message } = e.response.data;
      alert.error(message);
      setLoading(false);
    }
  };

  // useEffect(() => {
  //   let obj = { ...walletActive };

  //   obj.add = conversionRateList?.AddWallet?.isActive || obj.add;
  //   obj.deduct = conversionRateList?.DeductWallet?.isActive || obj.deduct;
  //   setWalletAcitve(obj);
  // }, [conversionRateList]);

  return (
    <>
      <Container>
        <h3 className="mb-5">{intl.formatMessage({ id: 'menu.conversion.rate' }, { point: pointText })}</h3>
        <br />
        <div
          style={{ display: 'flex', gap: '10px 20px', flexWrap: 'wrap', justifyContent: 'center', marginTop: '10px' }}
        >
          <Button
            style={selected.cart ? { ...background, width: '90px' } : { width: '90px' }}
            variant="outlined"
            onClick={() => select('cart')}
          >
            {intl.formatMessage({ id: 'menu.conversion.cart' })}
          </Button>
          {activeWallet && (
            <Button
              style={selected.wallet ? { ...background, width: '90px' } : { width: '90px' }}
              variant="outlined"
              onClick={() => select('wallet')}
            >
              {shopPointandWalletSetting?.point[1][intl.locale.toUpperCase()] ||
                intl.formatMessage({ id: 'menu.credit' })}
            </Button>
          )}
        </div>
        <br />
        {selected.cart && (
          <>
            <ConversionCard>
              <ConversionCardHeader>{formatMessage({ id: 'cr.money.to.point' })}</ConversionCardHeader>
              <ConversionCardContent>
                {conversionRateList.MoneyToPoint &&
                conversionRateList.MoneyToPoint.earn &&
                conversionRateList.MoneyToPoint.use ? (
                  <TextContent>
                    {intl.formatMessage({ id: 'cr.every' })} {addComma(conversionRateList.MoneyToPoint.use)}{' '}
                    {formatMessage({ id: 'shop.currency' })}, <br />
                    {intl.formatMessage({ id: 'cr.get' })} {addComma(conversionRateList.MoneyToPoint.earn)} {pointText}
                  </TextContent>
                ) : (
                  <TextContent>{intl.formatMessage({ id: 'cr.no.data' })}</TextContent>
                )}
                <button
                  className="btn btn-primary"
                  type="button"
                  onClick={() => handleOpenDialog(ConversionType.MoneyToPoint)}
                >
                  {intl.formatMessage({ id: 'button.edit' })}
                </button>
              </ConversionCardContent>
            </ConversionCard>
            {enableShoppingCart && (
              <>
                <ConversionCard>
                  <ConversionCardHeader>
                    {intl.formatMessage(
                      { id: 'cr.point.to.money' },
                      {
                        point:
                          shopPointandWalletSetting?.point[0][intl.locale.toUpperCase()] ||
                          intl.formatMessage({ id: 'menu.point' })
                      }
                    )}
                  </ConversionCardHeader>
                  <ConversionCardContent>
                    {conversionRateList.PointToMoney &&
                    conversionRateList.PointToMoney.earn &&
                    conversionRateList.PointToMoney.use ? (
                      <TextContent>
                        {intl.formatMessage({ id: 'cr.every' })} {addComma(conversionRateList.PointToMoney.use)}{' '}
                        {shopPointandWalletSetting?.point[0][intl.locale.toUpperCase()] ||
                          intl.formatMessage({ id: 'menu.point' })}
                        , <br />
                        {intl.formatMessage({ id: 'cr.get' })} {addComma(conversionRateList.PointToMoney.earn)}{' '}
                        {formatMessage({ id: 'shop.currency' })}
                      </TextContent>
                    ) : (
                      <TextContent>{intl.formatMessage({ id: 'cr.no.data' })}</TextContent>
                    )}
                    <button
                      className="btn btn-primary"
                      type="button"
                      onClick={() => handleOpenDialog(ConversionType.PointToMoney)}
                    >
                      {intl.formatMessage({ id: 'button.edit' })}
                    </button>
                  </ConversionCardContent>
                </ConversionCard>
                <ConversionCard>
                  <ConversionCardHeader>
                    {intl.formatMessage(
                      { id: 'cr.credit.to.money' },
                      {
                        wallet:
                          shopPointandWalletSetting?.point[1][intl.locale.toUpperCase()] ||
                          intl.formatMessage({ id: 'menu.credit' })
                      }
                    )}
                  </ConversionCardHeader>
                  <ConversionCardContent>
                    {conversionRateList.CreditToMoney &&
                    conversionRateList.CreditToMoney.earn &&
                    conversionRateList.CreditToMoney.use ? (
                      <TextContent>
                        {intl.formatMessage({ id: 'cr.every' })} {addComma(conversionRateList.CreditToMoney.use)}{' '}
                        {shopPointandWalletSetting?.point[1][intl.locale.toUpperCase()] ||
                          intl.formatMessage({ id: 'menu.credit' })}
                        , <br />
                        {intl.formatMessage({ id: 'cr.get' })} {addComma(conversionRateList.CreditToMoney.earn)}{' '}
                        {formatMessage({ id: 'shop.currency' })}
                      </TextContent>
                    ) : (
                      <TextContent>{intl.formatMessage({ id: 'cr.no.data' })}</TextContent>
                    )}
                    <button
                      className="btn btn-primary"
                      type="button"
                      onClick={() => handleOpenDialog(ConversionType.CreditToMoney)}
                    >
                      {intl.formatMessage({ id: 'button.edit' })}
                    </button>
                  </ConversionCardContent>
                </ConversionCard>
              </>
            )}
          </>
        )}

        {selected.wallet && (
          <>
            <ConversionCard>
              <ConversionCardHeader>
                {conversionRateList.AddWallet && conversionRateList.AddWallet.earn && conversionRateList.AddWallet.use && (
                  <Switch
                    disabled={isReadOnly}
                    checked={
                      conversionRateList?.AddWallet ? conversionRateList?.AddWallet?.isActive : walletActive?.add
                    }
                    onChange={() => {
                      active('add');
                    }}
                  />
                )}
                <label>
                  {intl.formatMessage(
                    { id: 'wallet.add' },
                    {
                      wallet:
                        shopPointandWalletSetting?.point[1][intl.locale.toUpperCase()] ||
                        intl.formatMessage({ id: 'menu.credit' })
                    }
                  )}
                </label>
              </ConversionCardHeader>
              <ConversionCardContent>
                {conversionRateList.AddWallet &&
                conversionRateList.AddWallet.earn &&
                conversionRateList.AddWallet.use ? (
                  <TextContent>
                    {intl.formatMessage({ id: 'cr.every' })} {addComma(conversionRateList.AddWallet.use)}{' '}
                    {formatMessage({ id: 'shop.currency' })}, <br />
                    {intl.formatMessage({ id: 'cr.get' })} {addComma(conversionRateList.AddWallet.earn)} {pointText}
                  </TextContent>
                ) : (
                  <TextContent>{intl.formatMessage({ id: 'cr.no.data' })}</TextContent>
                )}
                <button
                  className="btn btn-primary"
                  type="button"
                  onClick={() => handleOpenDialog(ConversionType.AddWallet)}
                >
                  {intl.formatMessage({ id: 'button.edit' })}
                </button>
              </ConversionCardContent>
            </ConversionCard>
            <ConversionCard>
              <ConversionCardHeader>
                {conversionRateList.DeductWallet &&
                  conversionRateList.DeductWallet.earn &&
                  conversionRateList.DeductWallet.use && (
                    <Switch
                      checked={
                        conversionRateList?.DeductWallet
                          ? conversionRateList?.DeductWallet?.isActive
                          : walletActive?.deduct
                      }
                      disabled={isReadOnly}
                      onChange={() => {
                        active('deduct');
                      }}
                    />
                  )}
                <label>
                  {' '}
                  {intl.formatMessage(
                    { id: 'wallet.deduct' },
                    {
                      wallet:
                        shopPointandWalletSetting?.point[1][intl.locale.toUpperCase()] ||
                        intl.formatMessage({ id: 'menu.credit' })
                    }
                  )}
                </label>
              </ConversionCardHeader>
              <ConversionCardContent>
                {conversionRateList.DeductWallet &&
                conversionRateList.DeductWallet.earn &&
                conversionRateList.DeductWallet.use ? (
                  <TextContent>
                    {intl.formatMessage({ id: 'cr.every' })} {addComma(conversionRateList.DeductWallet.use)}{' '}
                    {formatMessage({ id: 'shop.currency' })}, <br />
                    {intl.formatMessage({ id: 'cr.get' })} {addComma(conversionRateList.DeductWallet.earn)} {pointText}
                  </TextContent>
                ) : (
                  <TextContent>{intl.formatMessage({ id: 'cr.no.data' })}</TextContent>
                )}
                <button
                  className="btn btn-primary"
                  type="button"
                  onClick={() => handleOpenDialog(ConversionType.DeductWallet)}
                >
                  {intl.formatMessage({ id: 'button.edit' })}
                </button>
              </ConversionCardContent>
            </ConversionCard>
          </>
        )}
      </Container>
      <Dialog open={openDialog} maxWidth="sm" fullWidth>
        <div className="p-5">
          <h3>
            {formatMessage({
              id:
                ConversionType.CreditToMoney === openDialog
                  ? 'cr.credit.to.money'
                  : ConversionType.MoneyToPoint === openDialog
                  ? 'cr.money.to.point'
                  : ConversionType.PointToMoney === openDialog
                  ? 'cr.point.to.money'
                  : ConversionType.AddWallet === openDialog
                  ? 'wallet.add'
                  : 'wallet.deduct'
            })}
          </h3>
          <form className="d-flex flex-column align-items-center p-5" onSubmit={event => handleOnSubmit(event)}>
            <div className="form-group d-flex flex-column">
              <TextField
                name="use"
                label={getLabel().first}
                variant="outlined"
                type="number"
                style={{ width: '300px' }}
                onChange={handleOnChange}
                value={form.use}
                disabled={isReadOnly}
              />
            </div>
            <div className="form-group d-flex flex-column">
              <TextField
                name="earn"
                label={getLabel().second}
                variant="outlined"
                type="number"
                style={{ width: '300px' }}
                onChange={handleOnChange}
                value={form.earn}
                disabled={isReadOnly}
              />
            </div>
            <div className="d-flex flex-column align-items-center">
              {conversionRate && (
                <h6>
                  {formatMessage(
                    { id: 'cr.conversion.text' },
                    {
                      amount: conversionRate.rate,
                      points: conversionRate.earnPoint
                    }
                  )}
                </h6>
              )}
            </div>
            <div className="d-flex align-items-center">
              <button type="button" className="btn btn-secondary mr-3" onClick={() => setOpenDialog(false)}>
                {intl.formatMessage({ id: 'button.cancel' })}
              </button>
              {!isReadOnly && (
                <button type="submit" className="btn btn-primary" disabled={loading || isReadOnly}>
                  {intl.formatMessage({ id: 'button.submit' })}
                </button>
              )}
            </div>
          </form>
        </div>
      </Dialog>
    </>
  );
};

export default injectIntl(ConversionRate);

const ConversionCard = styled.div`
  border-radius: 8px;
  box-shadow: 5px 5px 10px rgba(0, 0, 0, 0.5);
  margin-bottom: 12px;
`;

const ConversionCardHeader = styled.div`
  font-weight: 500;
  font-size: 16px;
  line-height: 25px;
  color: #464e5f;
  border-bottom: 1px solid rgba(0, 0, 0, 0.1);
  padding: 16px;
`;

const ConversionCardContent = styled.div`
  display: flex;
  padding: 16px;
  width: 100%;
  justify-content: space-between;
`;

const TextContent = styled.div`
  font-weight: 400;
  font-size: 14px;
  line-height: 22px;
  color: #464e5f;
`;

const SpanText = styled.div`
  font-weight: 500;
  font-size: 14px;
  line-height: 22px;
  color: #464e5f;
`;
