import { useLocation } from 'react-router-dom';
import { useShouldSelectBranch } from './useShouldSelectBranch';

export function useLayoutVisibility() {
  const isSelectingLoginBranch = useShouldSelectBranch();

  const location = useLocation();
  const isHomePage = location.pathname.includes('/home');
  const isRewardPage = location.pathname.includes('/reward');
  const isShoppingPage = location.pathname.includes('/shopping');
  const isShopRewardPage = location.pathname.includes('/shop-reward');
  const isCheckoutPage = location.pathname.includes('/checkout');
  const isAddressPage = location.pathname.includes('/address');
  const isPaymentPage = location.pathname.includes('/payment');
  const isSlipPage = location.pathname.includes('/up-slip');
  const isOrderPage = location.pathname.includes('/order');
  const isPromotionPage = location.pathname.includes('/promotion/');
  const isShopProductPage = location.pathname.includes('/shopProduct/');
  const isShopProductPage2 =
    location.pathname.includes('/products/') &&
    (location.pathname.includes('/new') || location.pathname.includes('/edit'));
  //   const isOrderHistoryPage = location.pathname.includes('/order-history');
  //   const isorderManagementPage = location.pathname.includes('/orderManagement');
  const isInventoryPage = location.pathname.includes('inventory');
  const isShoppingCartSettingPage = location.pathname.includes('/shopping-cart-setting');
  const isLineOASettingPage = location.pathname.includes('/setting-line-oa');
  const isContactPage = location.pathname.includes('/board') || location.pathname.includes('customer-contact');
  const isRegistrationSettingPage = location.pathname.includes('registration-perks');
  const isAdminManagePage = location.pathname.includes('adminManage');
  const isBranchManagePage = location.pathname.includes('branch-management');
  const isAddOnPage = location.pathname.includes('add-on');
  const isSelectPaymentPage = location.pathname.includes('select-payment') && location.pathname.includes('order');
  const isSelectCategoryPage = location.pathname.includes('categories');
  const isDashboardPage = location.pathname.includes('statistics');

  const hideLayout =
    isHomePage ||
    isRewardPage ||
    isShoppingPage ||
    isShopRewardPage ||
    isCheckoutPage ||
    isAddressPage ||
    isPaymentPage ||
    isSlipPage ||
    isOrderPage ||
    isPromotionPage ||
    isShopProductPage ||
    isShopProductPage2 ||
    isInventoryPage ||
    isShoppingCartSettingPage ||
    isContactPage ||
    isLineOASettingPage ||
    isRegistrationSettingPage ||
    isAdminManagePage ||
    isBranchManagePage ||
    isContactPage ||
    isSelectingLoginBranch ||
    isAddOnPage ||
    isSelectPaymentPage ||
    isSelectCategoryPage ||
    isDashboardPage;

  return {
    hideLayout
  };
}
