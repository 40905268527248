import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  selectedId: null,
  entities: [],
  success: false, // used to navigate to another page
  addressForEdit: null,
  hasAutoFetched: false,
  mode: '' //CREATE / UPDATE / DELETE
};

//this slice is used to persist address selected between pages
export const addressSlice = createSlice({
  name: 'address',
  initialState,
  reducers: {
    addressesFetched: (state, action) => {
      const { payload } = action;
      state.entities = payload.entities;
      state.error = null;
    },
    addressSelected: (state, action) => {
      const addressId = action.payload;
      state.selectedId = addressId;
      state.error = null;
    },
    addressAutoSelected: (state, action) => {
      const addressId = action.payload;
      state.selectedId = addressId;
      state.hasAutoFetched = true;
    },
    addressFetched: (state, action) => {
      state.addressForEdit = action.payload.address;
      state.error = null;
    },
    catchError: (state, action) => {
      state.error = action.payload;
      state.success = false;
    },
    setSuccess: (state, action) => {
      state.error = null;
      state.success = action.payload;
    },
    setLoading: (state, action) => {
      state.loading = action.payload;
    },
    setMode: (state, action) => {
      state.mode = action.payload;
    },
    setHasAutoFetched: (state, action) => {
      state.hasAutoFetched = action.payload;
    }
  }
});
