import React from 'react';
import { toAbsoluteUrl } from '../../../_metronic/_helpers';
import { styled } from '@mui/material';
import useMediaQuery from '@mui/material/useMediaQuery';

export function ErrorPageLine() {
  return (
    <Container>
      <FuncrowdIC />
      <div style={{ marginBottom: '5vw' }}></div>
      <LineImage>
        <Detail>
          <H1>Somethings went wrong with LINE...</H1>
          <H1>มีบางอย่างผิดพลาดเกี่ยวกับไลน์</H1>
          <br />
          <H2>Sorry, the system is currenly process. </H2>
          <H2> Please wait a moment</H2>
          <H3>ขออภัยขณะนี้ระบบกำลังประมวณผล กรุณารอสักครู่</H3>
        </Detail>
      </LineImage>
    </Container>
  );
}

const FuncrowdIC = styled('div')(() => ({
  background: `url(${toAbsoluteUrl('/media/error/funcrowd.png')})`,
  backgroundSize: '100%  auto',
  width: '30vw',
  height: '7.3vw',
  alignItems: 'center',
  justifyContent: 'center'
}));

const Container = styled('div')(() => ({
  padding: '5vw 7vw'
}));

const LineImage = styled('div')(() => ({
  background: `url(${toAbsoluteUrl('/media/error/line.png')})`,
  backgroundRepeat: 'no-repeat',
  backgroundPosition: 'center',
  backgroundSize: '45% auto',
  width: '100%',
  height: '75%',
  padding: '5vw 7vw',
  margin: '1vw'
}));

const Detail = styled('div')(() => ({
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center'
}));

const H1 = styled('h1')(() => ({
  color: '#5254A0',
  fontSize: '3vw',
  fontWeight: '600'
}));

const H2 = styled('h1')(() => ({
  fontSize: '2vw',
  fontWeight: '600'
}));

const H3 = styled('h1')(() => ({
  fontSize: '1.8vw',
  fontWeight: '300'
}));
