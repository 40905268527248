import React from 'react';
import { useField } from 'formik';
import { FieldFeedbackLabel } from './FieldFeedbackLabel';

const getFieldCSSClasses = (touched, errors, disabled) => {
  const classes = ['form-control', 'form-control-solid'];
  if (touched && errors) {
    classes.push('is-invalid');
  }

  if (touched && !errors) {
    classes.push('is-valid');
  }

  if (disabled) {
    classes.push('form-control-disabled');
  }

  return classes.join(' ');
};

export function Select({
  label,
  withFeedbackLabel = true,
  type = 'text',
  customFeedbackLabel,
  children,
  field,
  form: { touched, errors },
  className,
  ...props
}) {
  return (
    <>
      {label && <label>{label}</label>}
      <select
        className={getFieldCSSClasses(touched[field.name], errors[field.name], props.disabled) || 'form-control'}
        style={{ backgroundColor: '#fff', border: '1px solid #E5EAEE' }}
        {...field}
        {...props}
      >
        {children}
      </select>
      {withFeedbackLabel && (
        <FieldFeedbackLabel
          error={errors[field.name]}
          touched={touched[field.name]}
          label={label}
          customFeedbackLabel={customFeedbackLabel}
        />
      )}
    </>
  );
}
