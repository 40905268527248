import axios from 'axios';
import { BACKEND_URL } from '../../../../../../config';

const featurePackageUrl = `${BACKEND_URL}/feature-package`;

export const createFeaturePackage = body => {
  return axios.post(featurePackageUrl, body);
};

export const updateFeaturePackage = (id, body) => {
  return axios.patch(`${featurePackageUrl}/${id}`, body);
};

export const getFeaturePackages = () => {
  return axios.get(featurePackageUrl);
};

export const getFeaturePackageById = id => {
  return axios.get(`${featurePackageUrl}/${id}`);
};
