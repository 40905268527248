import axios from 'axios';
import { BACKEND_URL } from '../../../../../config';

export const getGroups = async () => {
  const res = await axios.get(`${BACKEND_URL}/shopMemberGroup/`);

  return res;
};

export const addGroup = newGroup => {
  return axios.post(`${BACKEND_URL}/shopMemberGroup/`, newGroup);
};

export const deleteGroup = ({ newGroupId, groupId }) => {
  return axios.delete(`${BACKEND_URL}/shopMemberGroup/${groupId}/${newGroupId}`);
};

export const updateGroup = (groupForm, groupId) => {
  return axios.put(`${BACKEND_URL}/shopMemberGroup/${groupId}`, groupForm);
};

export const syncPointGroups = () => axios.post(`${BACKEND_URL}/shopMemberGroup/sync-groups`);
