import { useSelector } from 'react-redux';

//hook that tells if coupon module is enabled or not
export const useIsWalletActive = () => {
  // const role = useSelector(state => state.auth.user?.type) || 'customer';
  const shop = useSelector(state => state.auth.shop);
  const shopPointandWalletSetting = useSelector(state => state.auth.shopPointandWalletSetting);

  if (!shop) return false;
  const { enableWallet } = shop;
  const activeWallet = !shopPointandWalletSetting ? true : shopPointandWalletSetting?.point[1]?.active;

  return enableWallet && activeWallet;
};

//hook that tells if coupon module is enabled or not
export const useIsCouponActive = () => {
  const shop = useSelector(state => state.auth.shop);

  if (!shop) return false;
  const { enableCoupon } = shop;

  return !!enableCoupon;
};

export const useIsPackageActive = () => {
  const shop = useSelector(state => state.auth.shop);
  return !!shop?.enablePackage;
};

export const useIsPromotionActive = () => {
  const shop = useSelector(state => state.auth.shop);
  return !!shop?.enablePromotion;
};

export const useIsSalesQrActive = () => {
  const shop = useSelector(state => state.auth.shop);
  return !!shop?.enableSalesQr;
};

export const useIsShoppingCartActive = () => {
  const shop = useSelector(state => state.auth.shop);
  return !!shop?.enableShoppingCart;
};
