import { Field, FormikProvider, useFormik, useFormikContext } from 'formik';
import React, { useEffect } from 'react';
import { Input } from '../../../../../../_metronic/_partials/controls';
import { Container } from './styles';
import * as Yup from 'yup';
import { createFeaturePackage, getFeaturePackageById, updateFeaturePackage } from './services';
import { featurePackageSettingFields } from './featurePackageConstants';
import { useHistory } from 'react-router-dom';
import { navMenu } from '../contants';

const FeatureSchema = Yup.object().shape({
  name: Yup.string().required('Name is required'),
  settings: Yup.object().shape(
    featurePackageSettingFields.reduce((acc, cur) => {
      acc[cur.id] = Yup.boolean();
      return acc;
    }, {})
  )
});

function FeaturePackageEdit({
  match: {
    params: { featurePackageId }
  }
}) {
  const history = useHistory();
  const formik = useFormik({
    initialValues: {
      name: '',
      settings: featurePackageSettingFields.reduce((acc, cur) => {
        acc[cur.id] = cur.defaultValue;
        return acc;
      }, {})
    },
    validationSchema: FeatureSchema,
    onSubmit: values => {
      handleSubmit();
    }
  });

  async function handleSubmit() {
    try {
      if (!featurePackageId) {
        await createFeaturePackage(formik.values);
      } else {
        await updateFeaturePackage(featurePackageId, formik.values);
      }
      history.push({
        pathname: '/shop/management',
        search: `?menu=${navMenu.FEATURE_PACKAGE}`,
        state: true
      });
    } catch (error) {
      console.log(error);
    }
  }

  useEffect(() => {
    (async () => {
      try {
        const res = await getFeaturePackageById(featurePackageId);
        formik.setValues(res.data.featurePackage);
      } catch (error) {
        console.log(error);
      }
    })();
  }, []);

  return (
    <FormikProvider value={formik}>
      <Container>
        <form onSubmit={formik.handleSubmit}>
          <h2>FeaturePackageEdit</h2>
          <div className="my-3">
            <Field type="text" name="name" component={Input} label={'Name'} />
          </div>
          {featurePackageSettingFields.map(({ id, label }) => (
            <div key={id} className="d-flex">
              <input
                type="checkbox"
                className="mr-2"
                onChange={formik.handleChange}
                name={`settings.${id}`}
                checked={formik.values.settings[id]}
              />
              <div className="d-flex align-items-center">{label}</div>
            </div>
          ))}
          <button type="submit" className="btn btn-primary my-3">
            Save
          </button>
        </form>
      </Container>
    </FormikProvider>
  );
}

export default FeaturePackageEdit;
