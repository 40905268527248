import { createSlice } from '@reduxjs/toolkit';

const initialUserRoleTState = {
  items: undefined
};

export const UserRoleSlice = createSlice({
  name: 'userRole',
  initialState: initialUserRoleTState,
  reducers: {
    getUser: (state, action) => {
      state.data = action.payload.data;
    },
    saveCustomRole: (state, action) => {
      state.data = { ...state.data, ...action.payload.data };
    },
    saveCustomRoleReport: (state, action) => {
      state.data = { ...state.data, ...action.payload.data };
    }
  }
});
