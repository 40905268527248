import axios from 'axios';
import { BACKEND_URL } from '../../../../../config';
import moment from 'moment';

export const BOOKING_URL = `${BACKEND_URL}/booking`;
export const BOOKING_ADMIN_URL = `${BACKEND_URL}/booking/admin?`;
export const BOOKING_BRANCH_URL = `${BACKEND_URL}/booking/branch`;
export const BOOKING_SETTING_URL = `${BACKEND_URL}/booking/setting`;

export function fetchBookingManagement() {
  return axios.get(BOOKING_URL);
}

export function createBookingBranch(body) {
  return axios.post(BOOKING_BRANCH_URL, body);
}

export function getBookingBranch() {
  return axios.get(BOOKING_BRANCH_URL);
}

export function updateBookingBranch(id, body) {
  return axios.patch(`${BOOKING_BRANCH_URL}/${id}`, body);
}

export function deleteBookingBranch(id) {
  return axios.delete(`${BOOKING_BRANCH_URL}/${id}`);
}

export function createBookingSetting(body) {
  return axios.post(BOOKING_SETTING_URL, body);
}

export function updateBookingSetting(id, body) {
  return axios.patch(`${BOOKING_SETTING_URL}/${id}`, body);
}

export function getBookingSetting(seqId) {
  return axios.get(`${BOOKING_SETTING_URL}?seqId=${seqId}`);
}

export function createBooking(body) {
  return axios.post(BOOKING_URL, body);
}

export function getBooking(id) {
  return axios.get(`${BOOKING_URL}/${id}`);
}

export function updateBooking(id, body) {
  return axios.patch(`${BOOKING_URL}/${id}`, body);
}

export function cancelBooking(id) {
  return axios.post(`${BOOKING_URL}/cancel/${id}`);
}

export function confirmBooking(id, status) {
  return axios.patch(`${BOOKING_URL}/${id}`, { status });
}

export function getListBooking(isCustomer, status, date) {
  let url = isCustomer ? BOOKING_URL + '?' : BOOKING_ADMIN_URL;
  const queryObj = {};

  if (status) {
    queryObj.status = status;
  }
  if (date) {
    const currentDateTime = moment(date); // current datetime in local timezone
    const offsetMinute = moment(date).utcOffset(); // calculate the offset from local timezone to GMT

    const targetDateTime = currentDateTime.add(offsetMinute, 'minutes').format();
    //add +7 to the current time before calling .utc(). This will result in the same time being displayed, but in a different timezone.

    //instantiate a new moment to properly set the new date to moment object
    const realTargetDateTime = moment(targetDateTime)
      .utc()
      .startOf('day')
      .format();

    queryObj.date = new Date(realTargetDateTime).toISOString();
  }
  return axios.get(url + new URLSearchParams(queryObj));
}

export function getCustomerBookingTime(body) {
  return axios.post(`${BOOKING_URL}/seat`, body);
}

// const currentDateTime = moment(); // current datetime in local timezone
// const targetDateTime = currentDateTime.tz('Etc/GMT'); // convert to GMT timezone without changing time value
// const formattedDateTime = targetDateTime.format('YYYY/MM/DD HH:mm [GMT+0]'); // format the datetime string
// console.log(formattedDateTime);
